import React from "react";
import "../HeroSlider/Slider.css";
import Container from "../Container/Index";
import Slider from "../HeroSlider/slickSlider";
import Filter from "../Filter/Index";

const Index = ({ Data, mobileImage, heroHeading}) => {
  return (
    <Container _parentClass="slider_Section">
      <div className="slider herosection position-relative mb-3">
        <Slider
        heroHeading={heroHeading}
          slides={Data}
          mobileImage={mobileImage}
          mShow={'mdesktop-show'}
          slidesToShow={1}
          slidesToScroll={1}
          slide__content={"slide__content"}
          sliderImageWidth={"806"}
          sliderImageHeight={"656"}
          msliderImageWidth={"630"}
          msliderImageHeight={"800"}
          sliderClass={"radius-50"}
          sliderImageClass={"slide__img"}
        />
      </div>
      <Filter showBookAppointmentTab={true} filter_Class={'col-lg-8 col-sm-10 col-12 m-auto'}/>
    </Container>
  );
};

export default Index;