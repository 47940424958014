import React from "react";
import Container from "../Container/Index";
import Title from "../Title/Index";
import "../Testimonials/Testimonials.css";
import SlickSlider from "../HeroSlider/slickSlider";

const Index = ({ Data, snapHeading }) => {
  const responsiveSettings = [
    {
      breakpoint: 1050, 
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 680, 
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    }
  ];
  return (
    <Container _parentClass="doctors p-100 gray_Background">
      <Title firstHeading={snapHeading} secondHeading={Data.title} headingTwo={true}/>
      <div className="col-lg-10 m-auto position-relative slider">
        <SlickSlider
          slides={Data.testimonialsData}
          infinite={true}
          responsive={responsiveSettings} 
          slidesToShow={3}
          slidesToScroll={1}
          sliderClass={"doctorslide testimonialslide"}
          slide__content={
            "testimonialcont col-12 float-start bg-white d-flex flex-column-reverse"
          }
          showCircle={true}
          circlePosition={"circlePosition position-absolute"}
          showStar={true}
        />
      </div>
    </Container>
  );
};

export default Index;
