import React, { useState, useEffect } from "react";
import WebContainer from "./components/WebContainer/Index";
import Title from "./components/Title/Index";
import ImageTag from "./components/ImageTag/Index";
import "./components/Doctors/Doctors.css";
import Container from "./components/Container/Index";
import BreadCrumb from "./components/BreadCrumb/Index";
import Fill from "./components/Button/Fill/Index";
import axios from "axios";
import { BASE_URL } from "./Utils/useApi";
import Filter from "./components/Filter/Index";
import { useLocation } from "react-router-dom";

const Doctors = () => {
  const [pageData, setPageData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const params = new URLSearchParams(location.search);
        const specialization = params.get("s") || "";
        const doctor = params.get("d") || "";

        const response = await axios.get(`${BASE_URL}/doctor.php`, {
          params: {
            s: specialization,
            d: doctor,
          },
        });
        setPageData(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [location.search]);

  if (isLoading) {
    return <div className="preloader"></div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <Container _parentClass="gray_Background">
        <BreadCrumb pageName={"Doctors"} />
      </Container>
      <WebContainer _parentClass="doctors gray_Background p-100">
        <Title firstHeading={pageData.doctorsHead.heading} secondHeading={pageData.doctorsHead.sub_heading} _class={"mb-2"} />
        <Filter
          filter_Class="col-lg-9 col-12 m-auto"
          _parentClass="bg-transparent pt-0"
        />
        <div className="col-12 float-start">
          <div className="row flex-center">
            {pageData.doctors &&
              pageData.doctors.map((doctor) => (
                <div
                  key={doctor.slug}
                  className="col-lg-3 col-sm-4 col-12 pb-4"
                  data-aos="fade-in"
                  data-aos-offset="200"
                  data-aos-duration="600"
                  data-aos-once="true"
                  data-aos-easing="ease-in-sine"
                >
                  <div className="doctorsprofile">
                    <ImageTag ImagePath={doctor.imagePath} ImageClass="w-100" />
                  </div>
                  <div className="doctorsinfo col-12 float-start bg-white border p-4">
                    <h3>{doctor.name}</h3>
                    <div
                      className="col-12 float-start slide__height"
                      dangerouslySetInnerHTML={{ __html: doctor.designation }}
                    />
                    <Fill
                      buttonText1="Read More"
                      buttonText2="Read More"
                      _class="secondarybtn"
                      pageLink={`/doctors/${doctor.slug}`}
                    />
                  </div>
                </div>
              ))}
            {!pageData.doctors ||
              (pageData.doctors.length === 0 && (
                <div
                  className="col-12 text-center py-[30px] fw-600"
                  data-aos="fade-up"
                  data-aos-offset="100"
                  data-aos-duration="600"
                  data-aos-once="true"
                  data-aos-easing="ease-in-sine"
                >
                  No Doctors found
                </div>
              ))}
          </div>
        </div>
      </WebContainer>
    </>
  );
};

export default Doctors;
