import React, { useState, useEffect } from "react";
import axios from "axios";
import HeroSlider from "./components/HeroSlider/Index";
import AboutUs from "./components/AboutUs/Index";
import CountLoader from "./components/CountLoader/Index";
import WhyPatelHospital from "./components/AboutUs/WhyPatelHospital";
import Services from "./components/Services/Index";
import Doctors from "./components/Doctors/Index";
import Circle from "./components/Circle/Index";
import Testimonials from "./components/Testimonials/Index";
import CashLess from "./components/Services/CashLess";
import Accreditation from "./components/Accreditation/Index";
import { BASE_URL } from "./Utils/useApi";

const Home = () => {
  const [pageData, setPageData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/home.php`);
        setPageData(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  if (isLoading) {
    return <div className="preloader"></div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      {pageData && (
        <main className="col-12 float-start">
          {pageData.homeBanner && (
            <HeroSlider Data={pageData.homeBanner} mobileImage={true} heroHeading={true}/>
          )}
          {pageData.aboutUs && <AboutUs Data={pageData.aboutUs} />}
          {pageData.number && <CountLoader Data={pageData.number} />}
          {pageData.whypatel && <WhyPatelHospital Data={pageData.whypatel} />}
          {pageData.coe && (
            <Services Data={pageData.coe} showButton={true} cardLimit={9} />
          )}
          {pageData.doctors && (
            <Doctors
              Data={pageData.doctors}
              firstHeading={pageData.doctors.heading}
              secondHeading={pageData.doctors.sub_heading}
              showButton={true}
              showFilter={true}
              slideParent={"position-relative col-lg-11"}
            />
          )}
          {pageData.services && <Circle Data={pageData.services} />}
          {pageData.testimonials && (
            <Testimonials
              Data={pageData.testimonials}
              snapHeading={"PATIENT TESTIMONIALS"}
            />
          )}
          {pageData.cashless && (
            <CashLess Data={pageData.cashless} showButton={true} />
          )}
          {pageData.accreditation && (
            <Accreditation Data={pageData.accreditation} />
          )}
        </main>
      )}
    </>
  );
};

export default Home;
