import React from 'react'
import WebContainer from '../WebContainer/Index'
import Title from '../Title/Index'
import Circle from './Circle'
import './Circle.css'
import Ambulance from '../../images/Vectors/Ambulance';
import BloodBank from '../../images/Vectors/BloodBank';
import MRI from '../../images/Vectors/MRI'
import HomeCare from '../../images/Vectors/HomeCare';
import TestTube from '../../images/Vectors/TestTube';
import HomeSample from '../../images/Vectors/HomeSample'
import HealthCheckup from '../../images/Vectors/HealthCheckup'
import Coesvg from "../../images/Vectors/Coesvg";

const Index = ({Data}) => {
  const items = Data.cardData.map((item) => ({
    icon: (
      <Coesvg Data={item.path} themeColor={item.color} viewBox={item.viewBox} />
    ),
    text: item.Text,
  }));
  return (
    <WebContainer _parentClass={'Services'}>
            <Title firstHeading={'SERVICES'} secondHeading={Data.title} headingTwo={true}/>
            <Circle items={items}/>
    </WebContainer>
  )
}

export default Index