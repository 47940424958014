import React from "react";
import WebContainer from "../WebContainer/Index";
import Title from "../Title/Index";
import ImageTag from "../ImageTag/Index";
import VisionImg from "../../images/visionIcon.webp"
import MissionImg from "../../images/missionIcon.webp"

const Ethos = ({ Data, heading, Quote, description, heading2, Quote2, description2 }) => {
  return (
   <>
    <WebContainer _parentClass={'our__Ethos'}>
    <div className="col-lg-9 flex-center col-12 m-auto ">
<Title
  secondHeading={Data.our_ethos_title}
  firstHeading={"Our Ethos"}
  headingThree={true}
/>

    <div className="col-12 flex-center mb-lg-5 mb-3 pb-lg-5 pb-3 ourMission" data-aos="fade-in"
  data-aos-offset="200"
  data-aos-duration="500"
  data-aos-once="true"
  data-aos-easing="ease-in-sine">
      <div className="row">
      <div className="col-lg-6 col-12">
      <div className="col-12 flaot-start position-relative h-100">
          <h3 className="heading2 fw-bold verticleHeading opacity-50 lightColor text-uppercase">
            {Data.our_mission_heading}
          </h3>
          <div className="col-12 float-start">
          <p
              className="heading4 gap-3 d-lg-flex flex-wrap lh-1 text-black"
              dangerouslySetInnerHTML={{ __html: Data.our_mission_quote }}
            ></p>
            {Data.our_mission_desc.split(/\r\n\r\n|\r\n/).map((paragraph, index) => (
              <p key={index} dangerouslySetInnerHTML={{ __html: paragraph }} ></p>
            ))}
          </div>
        </div>
      </div>
      <div className="col-lg-6 col-12  flex-center">
      <ImageTag ImagePath={MissionImg} ImageAlt={Data.our_mission_heading}/>
      </div>
      </div>
      </div>
    <div className="col-12 flex-center mb-lg-5 mb-3 pb-lg-5 pb-3 ourVision" data-aos="fade-in"
  data-aos-offset="500"
  data-aos-duration="500"
  data-aos-once="true"
  data-aos-easing="ease-in-sine">
      <div className="row">
        <div className="col-lg-6 col-12  flex-center">
              <ImageTag ImagePath={VisionImg} ImageAlt={Data.our_vision_heading}/>
        </div>
        <div className="col-lg-6 col-12">
        <div className="col-12 flaot-start position-relative h-100">
          <h3 className="heading2 fw-bold verticleHeading lightColor opacity-50 text-uppercase">
            {Data.our_vision_heading}
          </h3>
          <div className="col-12 float-start">
            <p
              className="heading4 lh-1 text-black"
              dangerouslySetInnerHTML={{ __html: Data.our_vision_quote }}
            ></p>
            {Data.our_vision_desc.split(/\r\n\r\n|\r\n/).map((paragraph, index) => (
              <p key={index} dangerouslySetInnerHTML={{ __html: paragraph }} className="mb-3"></p>
            ))}
          </div>
        </div>
        </div>
          </div>            
    </div>
    </div>
</WebContainer>
          <WebContainer _parentClass={'coreValue p-100 number_Bg_Image coreValue'}>
          <div className="col-12 mt-5 core__Value flex-center float-start" data-aos="fade-in"
  data-aos-offset="500"
  data-aos-duration="500"
  data-aos-once="true"
  data-aos-easing="ease-in-sine">
      <div className="col-lg-10 flex-center col-12 m-auto ">
          <h3 className="heading2 fw-bold text-uppercase lightColor text-white opacity-50 text-center mb-5">
         {Data.our_values_heading}
          </h3>
          <div className="col-12 float-start text-white">
            <div className="row">
              <div className="col-lg-4 col-12">
              <p
              className="heading3 d-lg-grid primary-color lh-1"
            >Superior Patient <span>Experiences</span></p>
           <ul>
            <li>Patient First</li>
            <li>Positive Energy</li>
            <li>Indian Values</li>
           </ul>
              </div>
              <div className="col-lg-4 col-12">
              <p
              className="heading3 d-lg-grid primary-color lh-1"
            >Excellence in <span>Medicine</span></p>
           <ul>
            <li>Leading through Learning</li>
            <li>Together as a Team</li>
            <li>Innovation </li>
           </ul>
              </div>
              <div className="col-lg-4 col-12">
              <p
              className="heading3 d-lg-grid primary-color lh-1"
            >Ethical <span>Practice</span></p>
           <ul>
            <li>Integrity in Thought and Practice</li>
            <li>Committed to Sustainable Growth</li>
           </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
          </WebContainer>
   </>
  );
};

export default Ethos;
