import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ImageTag from "../ImageTag/Index";

const Accordiontabs = ({ treatments }) => {
  const [expanded, setExpanded] = useState(0); // Set the first tab as the default expanded tab

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const formatContent = (content) => {
    const isHTML = /<\/?[a-z][\s\S]*>/i.test(content);

    if (isHTML) {
      return content;
    }

    return content
      .split("\n")
      .filter((line) => line.trim() !== "") // Filter out empty lines
      .map((line, index) => `<p key=${index}>${line}</p>`)
      .join("");
  };

  return (
    <div className="col-12 m-auto position-relative overflow-hidden mb-5">
      {treatments.map((treatment, index) => (
        <Accordion
          expanded={expanded === index}
          onChange={handleChange(index)}
          key={index}
          className={`bg-transparent p-0 ${expanded === index ? "active" : ""}`}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
            className="p-0 accordionTitle"
          >
            <Typography>{treatment.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="row">
              <div className="accordionimg col-sm-4 col-12 text-center">
                <span className="m-auto mb-3 flex-center">
                  <ImageTag ImagePath={treatment.image} />
                </span>
              </div>
              <div className="accordioncont col-sm-8 col-12">
                <div className="col-12 float-start">
                  <Typography
                    component="div"
                    dangerouslySetInnerHTML={{
                      __html: formatContent(treatment.content),
                    }}
                  />
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default Accordiontabs;
