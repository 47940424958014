import React from "react";
import Container from "../Container/Index";
import Title from "../Title/Index";
import "../Doctors/Doctors.css";
import SlickSlider from "../HeroSlider/slickSlider";
import HearFelt from "../Doctors/HearFelt";
import Fill from "../Button/Fill/Index";
import Filter from "../Filter/Index";
import Outline from "../Button/Outline/Index";

const Index = ({ Data, slideParent, showButton, showFilter, secondHeading, firstHeading }) => {
  const doctorData = Data.doctorData;
  const slidesToShow = doctorData.length < 4 ? doctorData.length : 4;
  const responsiveSettings = [
    {
      breakpoint: 1050, 
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 680, 
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    }
  ];
  return (
    <Container _parentClass="doctors p-100 gray_Background">
      <Title firstHeading={firstHeading} secondHeading={secondHeading} headingTwo={true}/>

      {showFilter && (
        <div className="col-lg-8 col-12 m-auto filterHomeDoc">
          <Filter />
        </div>
      )}

      <div className={`${slideParent} col-12 m-auto slider`}>
        {doctorData.length > 3 ? (
          <SlickSlider
            docHeading={true}
            slides={doctorData}
            slidesToShow={slidesToShow}
            slidesToScroll={1}
            responsive={responsiveSettings} 
            sliderClass="doctorslide"
            slide__content="doctorsinfo col-12 float-start bg-white border p-4"
            sliderImageClass="doctorsprofile"
            sliderImageWidth="314"
            sliderImageHeight="259"
            sliderChild="slide__height"
            showButton={true}
          />
        ) : doctorData.length === 3 ? (
          <div className="col-12 float-start row">
          <div className="col-lg-8 col-12 m-auto row">
            {doctorData.map((doctor) => (
              <div className="col-lg-4 col-xs-6 col-12 pb-4" key={doctor.slug}>
                <div className="doctorsprofile">
                  <img
                    src={doctor.imagePath}
                    alt={doctor.imageAlt}
                    className="w-100"
                    width="314"
                    height="259"
                  />
                </div>
                <div className="doctorsinfo col-12 float-start bg-white border p-4">
                  <h3>{doctor.title}</h3>
                  <div className="col-12 float-start slide__height">
                    <p>{doctor.content}</p>
                  </div>
                  <Fill
                    pageLink={`/doctors/${doctor.slug}`}
                    buttonText1="Read More"
                    buttonText2="Read More"
                    _class="secondarybtn"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        ) : (
          <div className="col-12 float-start row">
            <div className="col-lg-7 col-12 width_1040 row justify-content-center">
              {doctorData.map((doctor) => (
                <div className="col-lg-5 col-sm-6 col-12 pb-4" key={doctor.slug}>
                  <div className="doctorsprofile">
                    <img
                      src={doctor.imagePath}
                      alt={doctor.imageAlt}
                      className="w-100"
                      width="314"
                      height="259"
                    />
                  </div>
                  <div className="doctorsinfo col-12 float-start bg-white border p-4">
                    <h3>{doctor.title}</h3>
                    <div className="col-12 float-start slide__height">
                      <p>{doctor.content}</p>
                    </div>
                    <Fill
                      pageLink={`/doctors/${doctor.slug}`}
                      buttonText1="Read More"
                      buttonText2="Read More"
                      _class="secondarybtn"
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className="col-lg-5 col-12 row desktop-show">
              <HearFelt
                imagePath={Data.doctor_alt_image}
                imageAlt={'Patel Experts Doctors'}
                headingText={Data.doctor_alt_text}
              />
            </div>
          </div>
        )}
      </div>

      {showButton && (
        <div className="col-12 float-start mt-5 flex-center">
          <Outline buttonText="View All" _class="secondarybtn mt-5" pageLink="/doctors" />
        </div>
      )}
    </Container>
  );
};

export default Index;
