import React from "react";
import WebContainer from "./components/WebContainer/Index";
import Title from "./components/Title/Index";
import Form from "./components/Filter/Form";
import Container from "./components/Container/Index";
import BreadCrumb from "./components/BreadCrumb/Index";

const BookAppointment = () => {
  return (
    <>
      {/* <Banner BannerImage={Bannerimage} pageName={'Book An Appointment'}/> */}
      <Container>
        <BreadCrumb pageName={"Book An Appointment"} />
      </Container>
      <WebContainer _parentClass="p-100 bookAnAppointment">
        <div className="row justify-content-between">
          <div className="col-lg-5 col-12">
            <div className="appoinmentNumber text-center">
              <Title
                secondHeading={"FOR APPOINTMENTS"}
                _classh4={"text-lg-start"}
                headingOne={true}
              />
              <ul className="p-0">
                <li>
                  <span>General Enquiry</span>
                  <p>
                    <a href="tel:01815241000">+91-(0)-181-5241000</a>
                  </p>
                </li>
                <li>
                  <span>Urology</span>
                  <p>
                    <a href="tel:01815241060">+91-(0)-181-5241060</a>
                  </p>
                </li>
                <li>
                  <span>Dermatology</span>
                  <p>
                    <a href="tel:01815241231">+91-(0)-181-5241231</a>
                  </p>
                </li>
                <li>
                  <span>Radiation</span>
                  <p>
                    <a href="tel:01815241187">+91-(0)-181-5241187</a>
                  </p>
                </li>
                <li>
                  <span>Surgery/Orthopaedics</span>
                  <p>
                    <a href="tel:01815241179">+91-(0)-181-5241179</a>
                  </p>
                </li>
                <li>
                  <span>Gynaecology</span>
                  <p>
                    <a href="tel:01815241290">+91-(0)-181-5241290</a>
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6 col-12">
            <div className="appointment_form">
              <Title
                secondHeading={"Book An APPOINTMENT"}
                _classh4={"text-lg-start"}
              />
              <Form />
            </div>
          </div>
        </div>
      </WebContainer>
    </>
  );
};

export default BookAppointment;
