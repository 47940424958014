import React from "react";

const Section = ({ title, items, offsetTitle }) => (
  <div className="col-lg-12 col-sm-12 col-12 position-relative mb-5 mt-5">
    {offsetTitle && (
      <div className="tpaBranches mb-5">
        <h2>{offsetTitle}</h2>
      </div>
    )}
    {title && <h2>{title}</h2>}
    <ul>
      {items.map((item, index) => (
        <li key={index}  data-aos="fade-in"
        data-aos-offset="100"
        data-aos-duration="500"
        data-aos-once="true"
        data-aos-easing="ease-in-sine">
          <span>{String(index + 1).padStart(2, "0")}</span>
          <label>{item}</label>
        </li>
      ))}
    </ul>
  </div>
);

export default Section;
