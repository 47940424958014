import React, { useEffect, useState } from "react";
import BreadCrumb from "./components/BreadCrumb/Index"
import WebContainer from "./components/WebContainer/Index";
import ImageTag from "./components/ImageTag/Index";
import Fill from "./components/Button/Fill/Index";
import { useParams } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "./Utils/useApi";
import Container from "./components/Container/Index";
import { CircularProgress } from "@mui/material";
import Error from "./Error";

const DoctorDetails = () => {
  const { slug } = useParams();
  const [pageData, setPageData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/doctor_details.php?url=${slug}`
        );
        if (response.data.error) {
          setError(response.data.error);
        } else {
          setPageData(response.data.doctorDetails);
        }
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [slug]);
  console.log("doctorData", pageData);
  const isNonEmptyHtml = (htmlString) => {
    const div = document.createElement('div');
    div.innerHTML = htmlString;
    return div.textContent.trim() !== '' || div.innerHTML.replace(/<[^>]+>/g, '').trim() !== '';
};
if (isLoading)
  return (
    <div className="preloader flex-center">
      <CircularProgress size={24} />
    </div>
  );
  if (!pageData)
    return (
      <Error />
    );
  if (error)
    return (
      <div className="  mx-auto p-100 w-100 d-flex justify-content-center">
        Error: {error}
      </div>
    );
  const slugToText = (slug) => {
    let text = slug.replace(/[-_]/g, " ");
    text = text.replace(/\b\w/g, (char) => char.toUpperCase());

    return text;
  };

  return (
    <>
      <main className="col-12 float-start" data-scroll-container>       
        <Container>
       <BreadCrumb
        pageName={"Doctors"}
        pageChildName={slugToText(slug)}
        pageUrl={"/doctors"}
      />
      </Container>
        <WebContainer _parentClass={"doctorsBio p-100 pt-0"}>
          <div className="row">
            <div className="col-lg-4 col-12 text-lg-custom-start mb-3">
              {pageData.imagePath && (
                <ImageTag ImagePath={pageData.imagePath} />
              )}
            </div>
            <div className="col-lg-8 col-12">
              <div className="doctorName mb-3 col-12 float-start text-lg-custom-start">
                {pageData.name && (
                  <h1 className="heading4 fw-600 text-black mb-1">
                    {pageData.name}
                  </h1>
                )}
                {pageData.designation && (
                  <p className="m-0">{pageData.designation}</p>
                )}
              </div>
              {pageData.education && (
                <div className="doctorInfo mt-4 col-12 float-start">
                  <div className="doctorTitle position-relative mb-3">
                    <h2 className="heading4 fw-600 secondary-color bg-white">
                      Education
                    </h2>
                  </div>
                  <div className="doctorCont">
                    {pageData.education.map((edu, index) => (
                      <p key={index}>{edu.trim()}</p>
                    ))}
                  </div>
                </div>
              )}
              {pageData.expertise && (
                <div className="doctorInfo mt-4 col-12 float-start">
                  <div className="doctorTitle position-relative mb-3">
                    <h2 className="heading4 fw-600 secondary-color bg-white">
                      Expertise
                    </h2>
                  </div>
                  <div className="doctorCont">
                    {/* <p>{pageData.expertise}</p> */}
                    {pageData.expertise.map((expt, index) => (
                      <p key={index}>{expt.trim()}</p>
                    ))}
                  </div>
                </div>
              )}
              {pageData.experience && (
                  <div className="doctorInfo mt-4 col-12 float-start">
                    <div className="doctorTitle position-relative mb-3">
                      <h2 className="heading4 fw-600 secondary-color bg-white">
                        Experience
                      </h2>
                    </div>
                    <div className="doctorCont">                    
                    {pageData.experience.filter(isNonEmptyHtml).map((exp, index) => (
                            <p key={index} dangerouslySetInnerHTML={{ __html: exp.trim() }} />
                        ))}
                    </div>
                  </div>
                )}
              <div className="col-lg-4 col-sm-4 col-12 float-start mt-5">
                  <Fill
                    buttonText1={"Book an appointment"}
                    buttonText2={"Book an appointment"}
                    _class={"secondarybtn w-auto "}
                    pageLink={'/book-an-appointment'}
                  />
              </div>
            </div>
          </div>
        </WebContainer>
      </main>
    </>
  );
};

export default DoctorDetails;
