import React, { useEffect, useState } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import Outline from "../../Button/Outline/Index";
import SubMenu from "../../Header/Navbar/SubMenu";
import "../Navbar/Navbar.css";
import { NavLink } from "react-router-dom";
import Container from "../../Container/Index";
import axios from "axios";
import { BASE_URL } from "../../../Utils/useApi";
import Coesvg from "../../../images/Vectors/Coesvg";

const Index = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  const formatPhoneNumber = (input) => {
    // Return early if input is undefined or null
    if (!input) return "";

    return input.replace(/(\+91-\(0\)-|[-\s()])/g, "");
  };

  const [menus, setActive] = useState(false);
  const [coexSubmenus, setCoexSubmenus] = useState([]);
  const [contactData, setcontactData] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/menu.php`);
        if (response.data.error) {
          setError(response.data.error);
        } else {
          setCoexSubmenus(response.data.navigation);
          setcontactData(response.data.contact_details);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleToggle = () => {
    setActive(!menus);
  };

  const handleMenuClick = () => {
    setActive(false);
  };

  const menuData = [
    { name: "Home", link: "/" },
    {
      name: "Centres of Excellence",
      link: "/centres-of-excellence",
      submenus: coexSubmenus.map((item) => ({
        icon: (
          <Coesvg
            Data={item.icon_path}
            themeColor={item.color}
            viewBox={item.viewbox}
          />
        ),
        text: item.category,
        link: item.activedata ? `/centres-of-excellence/${item.slug}` : null,
        link2: item.url ? `${item.url}` : null,
      })),
      showViewAllLink: true,
    },
    {
      name: "Services",
      link: "/services",
      showViewAllLink: false,
    },
    { name: "Doctors", link: "/doctors" },
    { name: "Cashless Service", link: "/cashless-service" },
    { name: "About Us", link: "/about-us" },
    { name: "Contact Us", link: "/contact-us" },
  ];

  return (
    <div className="navbar p-0">
      <div className="col-12 float-start navbarChild gap-3">
        <Outline
          buttonText="Book an Appointment"
          pageLink="/book-an-appointment"
          _linkClass={"desktop-show"}
        />
        <a
          href={`tel:${
            contactData?.header_number_phone
              ? formatPhoneNumber(contactData.header_number_phone)
              : ""
          }`}
          className={`desktop-show`}
        >
          <div
            className={`button-container-1 border-0 redColor d-flex text-white lh-1 flex-center py-2`}
          >
            <span className="col-12 float-start lh-1">
              {contactData.header_number_title}
            </span>
            <span className="lh-1">{contactData.header_number_phone}</span>
          </div>
        </a>
      </div>
      <div className="col-12 float-start navbarcolumn d-flex justify-content-end align-items-center">
        <div className="menuStrip">
          {!isLoading && !error && (
            <ul>
              {menuData.map((menu, index) => (
                <li key={index} className="desktop-show">
                  <span
                    data-aos="fade-in"
                    data-aos-offset="100"
                    data-aos-duration="500"
                    data-aos-once="true"
                    data-aos-easing="ease-in-sine"
                  >
                    {menu.link ? (
                      <NavLink to={menu.link} onClick={handleMenuClick}>
                        {menu.name}
                      </NavLink>
                    ) : (
                      menu.name
                    )}
                  </span>
                  {menu.submenus && menu.submenus.length > 0 && (
                    <SubMenu
                      submenus={menu.submenus}
                      showViewAllLink={menu.showViewAllLink}
                    />
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>
        <div
          className="menu_second_child"
          data-aos="fade-in"
          data-aos-offset="200"
          data-aos-duration="800"
          data-aos-once="true"
          data-aos-easing="ease-in-sine"
        >
          <button
            onClick={handleToggle}
            className={menus ? "hamburger active" : "hamburger"}
            aria-label="Toggle menu"
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
        <div className={menus ? "menus active" : "menus"}>
          <Container _parentClass={"dropdown-content"}>
            <div className="col-12 float-start flex-center justify-content-end">
              <ul>
                {menuData.map((menu, index) => (
                  <li
                    key={index}
                    className="mobile-show text-uppercase text-white float-start"
                  >
                    <span
                      data-aos="fade-in"
                      data-aos-offset="100"
                      data-aos-duration="500"
                      data-aos-once="true"
                      data-aos-easing="ease-in-sine"
                    >
                      {menu.link ? (
                        <NavLink to={menu.link} onClick={handleMenuClick}>
                          {menu.name}
                        </NavLink>
                      ) : (
                        menu.name
                      )}
                    </span>
                  </li>
                ))}
                <li className="text-uppercase text-white float-start">
                  <NavLink
                    to="https://careers.patelhospital.com/jobs/Careers"
                    target="_blank"
                    onClick={handleMenuClick}
                  >
                    Career
                  </NavLink>
                </li>
                <li className="text-uppercase text-white float-start">
                  <NavLink to="/media" onClick={handleMenuClick}>
                    Media
                  </NavLink>
                </li>
                <li className="text-uppercase text-white float-start">
                  <NavLink to="/download-reports" onClick={handleMenuClick}>
                    Download Reports
                  </NavLink>
                </li>
              </ul>
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Index;
