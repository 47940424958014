import React from 'react'

const Appoinment = (props) => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={55}
    height={55}
    viewBox="0 0 68 68"
    {...props}
  >
    <path d="M61.6 5H56c-.5-2.3-2.5-4-4.9-4s-4.4 1.7-4.9 4H21.8c-.5-2.3-2.5-4-4.9-4S12.4 2.7 12 5H6.4C3.4 5 1 7.4 1 10.4v41.7c0 3 2.4 5.4 5.4 5.4h32.7C40.8 63 45.9 67 51.9 67c6.5 0 11.9-4.6 13.1-10.7 1.2-1 2-2.6 2-4.2V10.4c0-3-2.4-5.4-5.4-5.4zM51.1 3c1.7 0 3 1.3 3 3s-1.3 3-3 3-3-1.3-3-3 1.4-3 3-3zM16.9 3c1.7 0 3 1.3 3 3s-1.3 3-3 3-3-1.3-3-3 1.3-3 3-3zM6.4 7H12c.5 2.3 2.5 4 4.9 4s4.4-1.7 4.9-4h24.5c.5 2.3 2.5 4 4.9 4s4.4-1.7 4.9-4h5.5c1.9 0 3.4 1.5 3.4 3.4v5.4H3v-5.4C3 8.5 4.5 7 6.4 7zm0 48.6C4.5 55.6 3 54 3 52.1V17.9h62v32.9c-1.3-6-6.7-10.5-13.1-10.5-7.4 0-13.4 6-13.4 13.4 0 .7.1 1.3.2 2H6.4zM51.9 65c-6.3 0-11.4-5.1-11.4-11.4s5.1-11.4 11.4-11.4 11.4 5.1 11.4 11.4S58.2 65 51.9 65z" />
    <path d="M24.7 21.3h-5.4c-.6 0-1 .4-1 1v5.4c0 .6.4 1 1 1h5.4c.6 0 1-.4 1-1v-5.4c0-.5-.4-1-1-1zm-1 5.4h-3.4v-3.4h3.4zM36.4 21.3H31c-.6 0-1 .4-1 1v5.4c0 .6.4 1 1 1h5.4c.6 0 1-.4 1-1v-5.4c0-.5-.4-1-1-1zm-1 5.4H32v-3.4h3.4zM42.7 28.7h5.4c.6 0 1-.4 1-1v-5.4c0-.6-.4-1-1-1h-5.4c-.6 0-1 .4-1 1v5.4c0 .6.5 1 1 1zm1-5.4h3.4v3.4h-3.4zM54.4 28.7h5.4c.6 0 1-.4 1-1v-5.4c0-.6-.4-1-1-1h-5.4c-.6 0-1 .4-1 1v5.4c0 .6.5 1 1 1zm1-5.4h3.4v3.4h-3.4zM13 31.9H7.6c-.6 0-1 .4-1 1v5.4c0 .6.4 1 1 1H13c.6 0 1-.4 1-1v-5.4c0-.5-.4-1-1-1zm-1 5.5H8.6V34H12zM24.7 31.9h-5.4c-.6 0-1 .4-1 1v5.4c0 .6.4 1 1 1h5.4c.6 0 1-.4 1-1v-5.4c0-.5-.4-1-1-1zm-1 5.5h-3.4V34h3.4zM42.7 39.4h5.4c.6 0 1-.4 1-1V33c0-.6-.4-1-1-1h-5.4c-.6 0-1 .4-1 1v5.4c0 .5.5 1 1 1zm1-5.5h3.4v3.4h-3.4zM13 42.6H7.6c-.6 0-1 .4-1 1V49c0 .6.4 1 1 1H13c.6 0 1-.4 1-1v-5.4c0-.6-.4-1-1-1zM12 48H8.6v-3.4H12zM24.7 42.6h-5.4c-.6 0-1 .4-1 1V49c0 .6.4 1 1 1h5.4c.6 0 1-.4 1-1v-5.4c0-.6-.4-1-1-1zm-1 5.4h-3.4v-3.4h3.4zM36.4 31.9H31c-.6 0-1 .4-1 1v5.4c0 .6.4 1 1 1h5.4c.6 0 1-.4 1-1v-5.4c0-.5-.4-1-1-1zm-1 5.5H32V34h3.4zM57.9 48l-8.8 9-3.1-4.4c-.3-.4-1-.5-1.4-.2s-.5.9-.2 1.4l3.8 5.3c.2.2.4.4.7.4s.6-.1.8-.3l9.7-9.8c.4-.4.4-1 0-1.4s-1.1-.4-1.5 0z" />
  </svg>
  )
}

export default Appoinment