import React, { useEffect, useState } from "react";
import Container from "./components/Container/Index";
import Webcontainer from "./components/WebContainer/Index";
import Title from "./components/Title/Index";
import BreadCrumb from "./components/BreadCrumb/Index";
import Gallery from "./components/Gallery/Index";
import { BASE_URL } from "./Utils/useApi";
import axios from "axios";

const PatientRoom = () => {
  const [pageData, setPageData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [activeCategory, setActiveCategory] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/patient_rooms.php`);
        setPageData(response.data);
        if (
          response.data &&
          response.data.category &&
          response.data.category.length > 0
        ) {
          const firstCategory = response.data.category[0];
          setSelectedCategory(firstCategory);
          setActiveCategory(firstCategory);
        }
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  if (isLoading) {
    return <div className="preloader"></div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!pageData || !pageData.category) {
    return <div>No data available</div>;
  }

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setActiveCategory(category); // Update active category
  };

  const filteredpatientRoomData = pageData.data.filter(
    (item) => item.category === selectedCategory
  );

  const uniqueMonths = [
    ...new Set(
      pageData.data.map((item) => {
        const date = new Date(item.datemonth);
        return date.toLocaleString("default", { month: "short" }).toUpperCase();
      })
    ),
  ];

  const uniqueYears = [
    ...new Set(
      pageData.data.map((item) => {
        const date = new Date(item.datemonth);
        return date.getFullYear().toString();
      })
    ),
  ];

  return (
    <>
      <Container>
        <BreadCrumb pageName={"Patient Rooms"} />
      </Container>
      <Webcontainer _parentClass={"p-100 patientRoom"}>
        <Title
          secondHeading={`${selectedCategory} Gallery`}
          firstHeading={"Patient Rooms"}
        />
        <div className="row">
          <div className="col-lg-3 col-sm-3 col-12 oveflow-auto">
            <ul className="medianavbar">
              {pageData.category.map((category) => (
                <li
                  key={category}
                  className={activeCategory === category ? "active" : ""}
                  onClick={() => handleCategoryChange(category)}
                >
                  <a>{category}</a>
                </li>
              ))}
            </ul>
          </div>

          <Gallery
            gallery_data={filteredpatientRoomData}
            photoGallery={true}
            uniqueMonths={uniqueMonths}
            uniqueYears={uniqueYears}
          />
        </div>
      </Webcontainer>
    </>
  );
};

export default PatientRoom;
