import React, { useState } from "react";
import Container from "../Container/Index";
import Title from "../Title/Index";
import SlickSlider from "../HeroSlider/slickSlider";
import TreatmentCard from "./TreatmentCard";

const Technology = ({ Data, Data2 }) => {
  const slidesToShow = Data.length < 4 ? Data.length : 3;
  const [selectedSlide, setSelectedSlide] = useState(null);
  const [activeSlide, setActiveSlide] = useState(null);

  const activeContentToItems = (content) => {
    if (!content || typeof content !== 'string') {
      return []; 
    }
  };

  const handleSlideClick = (slide, index) => {
    if (activeSlide === index) {
      setSelectedSlide(null);
      setActiveSlide(null);
    } else {
      setSelectedSlide(slide);
      setActiveSlide(index);
    }
  };
  const responsiveSettings = [
    {
      breakpoint: 1050, 
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 768, 
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    }
  ];
  return (
    <Container _parentClass="technology mb-5">
      <Title
        firstHeading="Technology & Facilities"
        secondHeading={Data2}
      />
      <div className="col-12 position-relative slider">
        {Data.length > 3 ? (
          <SlickSlider
            slides={Data}
            docHeading={true}
            slidesToShow={slidesToShow}
            responsive={responsiveSettings} 
            slidesToScroll={1}
            sliderClass="doctorslide"
            slide__content="doctorsinfo col-12 float-start p-4 gray-background technolog__content"
            sliderImageClass="doctorsprofile"
            sliderImageWidth="314"
            sliderImageHeight="259"
            sliderChild="d-none"
            onSlideClick={handleSlideClick}
            activeSlide={activeSlide}
            showContent={false}
            _parentButton={"d-block"}
            loop={false} 
          />
        ) : (
         <>
          <TreatmentCard   
            slides={Data}
            sliderClass="doctorslide"
            slide__content="doctorsinfo col-12 float-start p-4 gray-background technolog__content"
            sliderImageClass="doctorsprofile"
            sliderImageWidth="314"
            sliderImageHeight="259"
            sliderChild="d-none"
            onSlideClick={handleSlideClick}
            activeSlide={activeSlide}
            _parentButton={"d-block"}/>
        
         </>
        )}
      </div>
      {selectedSlide && selectedSlide.content && (
  <div className="selected-slide-content col-lg-11 px-5 m-auto col-12 mt-5">
    <h3 className="col-12 float-start heading5 fw-600 text-uppercase">{selectedSlide.title}</h3>
    <ul className="col-12 float-start">
      {selectedSlide.content
        .filter(item => item.trim() !== '') 
        .map((item, index) => (
          <li key={index} dangerouslySetInnerHTML={{ __html: item }}></li>
        ))}
    </ul>
  </div>
)}
    </Container>
  );
};

export default Technology;
