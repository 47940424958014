import React, { useEffect, useState } from "react";
import BreadCrumb from "./components/BreadCrumb/Index";
import { BASE_URL } from "./Utils/useApi";
import CashLess from "./components/Services/CashLess";
import axios from "axios";
import Container from "./components/Container/Index";
import "./components/Services/CashLess.css";

const Cashless = () => {
  const [pageData, setPageData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/cashless_services.php`);
        setPageData(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);
  if (isLoading) {
    return <div className="preloader"></div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  return (
    <>
      <main className="col-12 float-start p-100 pt-0">
        <Container>
          <BreadCrumb pageName={"Cashless"} />
        </Container>
        {pageData.cashless && <CashLess Data={pageData.cashless} />}
      </main>
    </>
  );
};

export default Cashless;
