import React from "react";
import Container from "./components/Container/Index";
import Webcontainer from "./components/WebContainer/Index";
import Title from "./components/Title/Index";
import BreadCrumb from "./components/BreadCrumb/Index";
import MediaNavabar from "./components/Header/MediaNavabar";
import Gallery from "./components/Gallery/Index";

const Video = () => {
  const galleryData = [
    {
      videoUrl: "https://www.youtube.com/embed/dbAh054hOuA",
      titleData:
        "मूत्राशय का कैंसर : चेतावनी के संकेत, जाँच और उपचार | डॉ स्वपन सूद | पटेल अस्पताल, जालंधर",
      news_paperName:
        "मूत्राशय का कैंसर : चेतावनी के संकेत, जाँच और उपचार | डॉ स्वपन सूद | पटेल अस्पताल, जालंधर",
      datemonth: "Sep 2023",
    },

    {
      videoUrl: "https://www.youtube.com/embed/ALwqBGbqR_M?si=ZvQoJlRnIpUiZnqy",
      titleData:
        "यूरिनरी/किडनी स्टोन्स | कारण, लक्षण और इलाज़ | डॉ स्वपन सूद | पटेल हॉस्पिटल, जालंधर",
      news_paperName:
        "यूरिनरी/किडनी स्टोन्स | कारण, लक्षण और इलाज़ | डॉ स्वपन सूद | पटेल हॉस्पिटल, जालंधर",
      datemonth: "Mar 2022",
    },
  ];

  const uniqueMonths = [
    ...new Set(
      galleryData.map((item) => {
        const date = new Date(item.datemonth);
        return date.toLocaleString("default", { month: "short" }).toUpperCase();
      })
    ),
  ];

  const uniqueYears = [
    ...new Set(
      galleryData.map((item) => {
        const date = new Date(item.datemonth);
        return date.getFullYear().toString();
      })
    ),
  ];

  return (
    <>
      <Container>
        <BreadCrumb pageName={"Video"} />
      </Container>
      <Webcontainer _parentClass={"p-100"}>
        <Title
          secondHeading={"PATIENT INFORMATION VIDEO"}
          firstHeading={`Videos`}
        />
        <div className="row">
          <MediaNavabar />
          <Gallery
            gallery_data={galleryData}
            uniqueMonths={uniqueMonths}
            uniqueYears={uniqueYears}
            photoGallery={false}
          />
        </div>
      </Webcontainer>
    </>
  );
};

export default Video;
