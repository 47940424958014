import React from 'react';
import BreadCrumb from "./components/BreadCrumb/Index";
import Container from './components/Container/Index';
import WebContainer from './components/WebContainer/Index';
import Title from './components/Title/Index';
import Report from './images/annual-report2018.pdf'
import Report2 from './images/annual-report2017.pdf'

const biometricData = [
    {
        "reportYear": "2017",
        "reportUrl" : Report2,
        "data": [
            {
                "category": "YELLOW",
                "monthlyData": [173, 463.37, 564.59, 545.15, 615.16, 597.85, 635.88, 529.09, 509.51, 364.67, 475.76, 598.46]
            },
            {
                "category": "SHARPS (WHITE)",
                "monthlyData": [18, 28.06, 41.69, 58.41, 99.41, 71.55, 49.19, 38.22, 56.11, 63.84, 25.65, 14.28]
            },
            {
                "category": "BLUE CARD-BOARD BOX",
                "monthlyData": [51, 106.78, 188.20, 175.21, 333.54, 271.99, 257.02, 322.75, 320.03, 359.02, 422.61, 464.46]
            },
            {
                "category": "RED",
                "monthlyData": [192, 504.81, 577.96, 575.53, 598.53, 585.71, 594.63, 456.81, 321.55, 439.02, 574.18, 709.35]
            }
        ]
    },
    {
        "reportYear": "2018",
        "reportUrl" : Report,
        "data": [
            {
                "category": "YELLOW",
                "monthlyData": [509.51, 364.67, 475.76, 598.46, 590.46, 533.3, 340, 655, 770.09, 488, 538.27, 494.83]
            },
            {
                "category": "SHARPS (WHITE)",
                "monthlyData": [56.11, 63.84, 25.65, 14.28, 69.39, 22.53, 54, 36.29, 11.15, 11, 43.17, 14.555]
            },
            {
                "category": "BLUE CARD-BOARD BOX",
                "monthlyData": [320.03, 359.02, 422.61, 464.46, 302.21, 336.24, 332, 390, 346.58, 231, 189.425, 63.095]
            },
            {
                "category": "RED",
                "monthlyData": [321.55, 439.02, 574.18, 709.35, 634.57, 651.26, 560, 764.03, 602.23, 480, 656.21, 520.99]
            }
        ]
    }
];

const BioMetric = () => {
    const months = [
        "JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE",
        "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"
    ];

    const calculateTotal = (monthlyData) => {
        return monthlyData.reduce((acc, curr) => acc + curr, 0).toFixed(2);
    };

    return (
        <>
            <main className="col-12 float-start" data-scroll-container>
                <Container _parentClass={'breadcrumb'}>
                    <BreadCrumb pageName="BIO MEDICAL WASTE" />
                </Container>
                <WebContainer>
                    <Title firstHeading="Patel Hospital" secondHeading="BIO MEDICAL WASTE" headingOne={true}/>
                    <div className="pageContent">
                        {biometricData.map((yearData, yearIndex) => (
                            <div key={yearIndex} className="year-report  p-100 pt-0 col-12 float-start">
                                <table align="center" cellSpacing="0" className="table table-bordered table-responsive table-condensed table-hover">
                                    <thead>
                                        <tr>
                                            <td colSpan="14">
                                                <h2 className='py-3 fw-600 fs-4'>
                                                    Patel Hospital BIOMEDICAL WASTE GENERATED MONTHLY FOR YEAR {yearData.reportYear} (in kgs)
                                                </h2>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th><strong>CATEGORIES</strong></th>
                                            {months.map((month, idx) => (
                                                <th key={idx}><strong>{month} {yearData.reportYear.slice(-2)}</strong></th>
                                            ))}
                                            <th><strong>TOTAL</strong></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {yearData.data.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.category}</td>
                                                {item.monthlyData.map((value, idx) => (
                                                    <td key={idx} align="CENTER">{value}</td>
                                                ))}
                                                <td align="CENTER"><strong>{calculateTotal(item.monthlyData)}</strong></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div className='col-12 float-start flex-center mt-5'>
                                <a href={yearData.reportUrl} target='_blank'>
          <div className={`button-container-1 border-0 secondarybtn manageWidth`}>
            <span className="mas">{`View Annual Report ${yearData.reportYear}`}</span>
            <button type="button" name="Hover">
            {`View Annual Report ${yearData.reportYear}`}
            </button>
          </div>
        </a>
                                </div>
                            </div>
                        ))}
                    </div>
                </WebContainer>
            </main>
        </>
    );
};

export default BioMetric;
