import React from "react";
import Container from "../Container/Index";
import ImageTag from "../ImageTag/Index";
import BannerHeading from "./BannerHeading";
import "./Banner.css";
import BreadCrumb from "../BreadCrumb/Index";
import defaultBanner from '../../images/bannerLayer.webp'
import Coesvg from '../../images/Vectors/Coesvg'

const ExcellenceBanner = ({
  BannerImage,
  BannerName,
  BannerWidth,
  BannerHeight,
  bannerContent,
  pageName,
  pageChildName,
  pageUrl,
  _bannerHeight,
  rightPosition,
  colorClass,
  icon_path,
  color,
  viewbox  
}) => {
  if (!icon_path) {
    return null;
  }

  return (
    <Container _parentClass={`banner ${_bannerHeight}`}>
      <div className="col-12 float-start position-relative" >

        {icon_path && (
          <div className="col-12 float-start coeBanner">
            <ImageTag
              ImagePath={defaultBanner}
              ImageAlt={BannerName}
              ImageWidth={BannerWidth}
              ImageHeight={BannerHeight}
              ImageClass={"radius-25"}
            />
            <div className="BannerCOEdetail radius-25">

              {/* <ImageTag
                ImagePath={BannerImage}
                ImageAlt={BannerName}
                ImageWidth={BannerWidth}
                ImageHeight={BannerHeight}
                ImageClass={"radius-25 "}
              /> */}
        <div className='interSVGicon'>
          {icon_path && (
          <Coesvg
            Data={icon_path}
            themeColor={color}
            viewBox={viewbox}
          />
          ) }
          </div>
              <BannerHeading
                bannerText={bannerContent}
                _parentBanner={`coeBanner position-relative ${rightPosition}`}
                bannerHeading={`bannerh2 text-uppercase ${colorClass}`}
              />
            </div>

          </div>

        )}

      </div>
      <BreadCrumb
        pageName={pageName}
        pageChildName={pageChildName}
        pageUrl={pageUrl}
      />
    </Container>
  );
};

export default ExcellenceBanner;
