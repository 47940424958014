import React from "react";
import WebContainer from "../WebContainer/Index";
import Title from "../Title/Index";
import Card from "../Card/Index";
import "../Services/Services.css";
import Outline from "../Button/Outline/Index";
import { Link } from "react-router-dom";

const Index = ({ Data, _internalExellence, showButton, cardLimit, headingOne }) => {
  const displayedCards = cardLimit ? Data.coeData.slice(0, cardLimit) : Data.coeData;

  return (
    <WebContainer _parentClass={`excellence ${_internalExellence}`}>
      <Title
        secondHeading={"Centres of Excellence"}
        _classh4={"mt-0"}
        Data={Data}
      />
      <div className="col-12 float-start">
        <div className="row">
          {displayedCards.map((card, index) => (
            <div
              key={index}
              className="col-lg-4 col-sm-6 col-12 coe"
              data-aos="fade-in"
              data-aos-offset="100"
              data-aos-duration="500"
              data-aos-once="true"
              data-aos-easing="ease-in-sine"
            >
              {card.activedata ? (
                <Link to={`/centres-of-excellence/${card.slug}`}>
                  <Card
                    icon_path={card.icon_path}
                    color={card.color}
                    viewbox={card.viewbox}
                    cardCont={card.cardCont}
                    cardHeading={card.cardHeading}
                    // cardImage={card.cardImage}
                    _cardClass={"w-100 mb-5 loadeffect"}
                    cardBox={"serviceBox"}
                    childCard={"serviceChild w-100"}
                    // cardImageHeight={"219"}
                    // cardImageWidth={"323"}
                  />
                </Link>
              ) : card.url ? (
                <Link to={`${card.url}`} target="_blank">
                  <Card
                    icon_path={card.icon_path}
                    color={card.color}
                    viewbox={card.viewbox}
                    cardCont={card.cardCont}
                    cardHeading={card.cardHeading}
                    // cardImage={card.cardImage}
                    _cardClass={"w-100 mb-5 loadeffect"}
                    cardBox={"serviceBox"}
                    childCard={"serviceChild w-100"}
                    // cardImageHeight={"219"}
                    // cardImageWidth={"323"}
                  />
                </Link>
              ) : (
                <Card
                  icon_path={card.icon_path}
                  color={card.color}
                  viewbox={card.viewbox}
                  cardCont={card.cardCont}
                  cardHeading={card.cardHeading}
                  // cardImage={card.cardImage}
                  _cardClass={"w-100 mb-5 loadeffect"}
                  cardBox={"serviceBox"}
                  childCard={"serviceChild w-100"}
                  // cardImageHeight={"219"}
                  // cardImageWidth={"323"}
                />
              )}
            </div>
          ))}
          {showButton && (
            <div className="col-12 float-start mt-5 flex-center">
           
                <Outline buttonText={"View All"} _class={"secondarybtn"} pageLink={'/centres-of-excellence'}/>
          
            </div>
          )}
        </div>
      </div>
    </WebContainer>
  );
};

export default Index;
