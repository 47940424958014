import * as React from "react";

const Youtube = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21000 21043.9"
    width="210mm"
    height="210.439mm"
    style={{
      shapeRendering: "geometricPrecision",
      textRendering: "geometricPrecision",
      imageRendering: "optimizeQuality",
      fillRule: "evenodd",
      clipRule: "evenodd",
    }}
    enableBackground="new"
  >
    <defs>
      <style type="text/css" />
    </defs>
    <g id="Layer_x0020_1" fill="#000">
      <metadata id="CorelCorpID_0Corel-Layer" />
      <path
        className="fil0"
        d="M10500 -0.66c-5802.36,0 -10521.95,4720.9 -10521.95,10522.61 0,5801.71 4719.59,10522.61 10521.95,10522.61 5802.36,0 10521.95,-4720.9 10521.95,-10522.61 0,-5801.71 -4719.59,-10522.61 -10521.95,-10522.61zm0 20343.06c-5415.19,0 -9821.1,-4405.25 -9821.1,-9820.45 0,-5415.2 4405.91,-9820.45 9821.1,-9820.45 5415.19,0 9821.1,4405.25 9821.1,9820.45 0,5415.2 -4405.91,9820.45 -9821.1,9820.45zm-1327.04 -12099.37c-2.09,-0.02 -4.39,-0.05 -6.58,-0.05 -157.33,0 -284.92,127.58 -284.92,284.89 0,0 0,0.02 0,0.02l0 3988.05c0.08,157.18 127.66,284.66 284.92,284.66 50.39,0 100,-13.42 143.53,-38.78l3418.62 -1994.05c87.25,-51.05 141,-144.74 141,-245.85 0,-101.19 -53.75,-194.88 -141.08,-245.93l-3418.62 -1994.05c-41.51,-24.32 -88.67,-37.75 -136.82,-38.93l-0.05 0.02zm-2004.66 -2163.33c-1533.32,0 -2776.41,1243.09 -2776.41,2776.41l0 3331.7c0,1533.33 1243.09,2776.41 2776.41,2776.41l6663.4 -0.02c1533.32,0 2776.41,-1243.09 2776.41,-2776.41l0 -3331.7c0,-1533.33 -1243.09,-2776.41 -2776.41,-2776.41l-6663.4 0.02zm0 555.29l6663.4 0c1235.31,0 2221.12,985.81 2221.12,2221.12l0 3331.7c0,1235.32 -985.81,2221.13 -2221.12,2221.13l-6663.4 -0.03c-1235.31,0 -2221.12,-985.81 -2221.12,-2221.12l0 -3331.7c0,-1235.32 985.81,-2221.13 2221.12,-2221.13l0 0.03z"
      />
    </g>
  </svg>
);

export default Youtube;
