import React from "react";
import "../AboutUs/Aboutus.css";
import Webcontainer from "../WebContainer/Index";
import { Parallax } from "react-parallax";
import DoubleQuote from "../../images/Vectors/DoubleQuote";
import Quote from "../../images/Vectors/Quote";

const QuoteBanner = ({
  bannerImage,
  quoteText,
  quoteAuthor,
  _QuoteCenter,
  _QuoteHeading,
  Quote2,
  panditApte,
  panditText,
  quote2title,
  quoute2subheading,
  qouteText,
  quoteText3,
  quoteAuthor3,
}) => {
  const getStrengthValue = () => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.platform);
    const isMac = /MacIntel/.test(navigator.platform);

    return isIOS || isMac ? 100 : 300;
  };

  return (
    <>
      <section className="col-12 float-start quote__banner position-relative">
        <Parallax
          bgImage={bannerImage}
          strength={getStrengthValue()}
          className="flex-center"
        >
          <div
            className={`${_QuoteCenter} m-auto quote_Content text-center text-white`}
          >
            <span>
              <DoubleQuote />
            </span>
            <div
              className="col-12 flex-center text-white position-relative"
              data-aos="fade-up"
              data-aos-offset="200"
              data-aos-duration="500"
              data-aos-once="true"
              data-aos-easing="ease-in-sine"
            >
              {quoteText.split("\r\n").map((paragraph, index) => (
                <h3
                  className={`text-white fw-100 mt-4 ${_QuoteHeading}`}
                >
                  {paragraph}
                </h3>
              ))}
             
              {panditApte && (
                <>
                  {panditText.split("\r\n").map((paragraph, index) => (
                    <h3
                      className={`text-white fw-100 mt-4 ${_QuoteHeading}`}
                    
                    >
                      {paragraph}
                    </h3>
                  ))}
                </>
              )}
              <p className="text-white mt-5">{quoteAuthor}</p>
            </div>
          </div>
        </Parallax>
      </section>
      
      {Quote2 && (
        <Webcontainer _parentClass={'coreValues'}>
          <div className="col-lg-11 m-auto flex-center align-items-start justify-content-between">
            <div
              className="col-lg-5 col-12"
              data-aos="fade-in"
              data-aos-offset="500"
              data-aos-duration="500"
              data-aos-once="true"
              data-aos-easing="ease-in-sine"
            >
              <div className="blue__circle_heading flex-center">
                <h4>{quote2title}</h4>
              </div>
            </div>
            <div
              className="col-lg-6 col-12"
              data-aos="fade-in"
              data-aos-offset="200"
              data-aos-duration="500"
              data-aos-once="true"
              data-aos-easing="ease-in-sine"
            >
              <h4 className="heading3 secondary-color m-0">
                <strong className="fw-bold">{quoute2subheading}</strong>
              </h4>
              {qouteText.split("\r\n\r\n").map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
              ))}
              <div className="quote__Text_sec">
                <span className="upperQuote">
                  <Quote />
                </span>
                <div
                  className="col-12 position-relative"
                  dangerouslySetInnerHTML={{ __html: quoteText3 }}
                ></div>
                <p className="mt-4">{quoteAuthor3}</p>

                <span className="downQuote">
                  <Quote />
                </span>
              </div>
            </div>
          </div>
        </Webcontainer>
      )}
    </>
  );
};

export default QuoteBanner;
