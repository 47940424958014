import React from "react";
import WebContainer from "../WebContainer/Index";
import Title from "../Title/Index";
import ImageTag from "../ImageTag/Index";
import DoubleQuote from "../../images/Vectors/DoubleQuote";

const OurStory = ({
  firstHeading,
  secondHeading,
  description,
  imagePath,
  imageAlt,
  quote,
  quoteAuthor,
  story,
}) => {
  return (
    <WebContainer _parentClass={'ourStory'}>
      <Title firstHeading={firstHeading} secondHeading={secondHeading} _class={'mb-3'}/>
      <p className="col-lg-7 col-12 m-auto text-center" data-aos="fade-up"
        data-aos-offset="200"
        data-aos-duration="500"
        data-aos-once="true"
        data-aos-easing="ease-in-sine">{description}</p>
      <div className="col-lg-8 col-12 m-auto row mt-5 align-items-center">
        <div className="col-lg-4 col-sm-6 col-12 text-lg-end" data-aos="fade-in"
        data-aos-offset="200"
        data-aos-duration="400"
        data-aos-once="true"
        data-aos-easing="ease-in-sine">
          <ImageTag ImagePath={imagePath} ImageAlt={imageAlt} ImageClass={'m-auto'}/>
        </div>
        <div className="col-lg-8 col-12 quote_Content position-relative patelji" data-aos="fade-in"
        data-aos-offset="300"
        data-aos-duration="600"
        data-aos-once="true"
        data-aos-easing="ease-in-sine">
          <span><DoubleQuote /></span>
          <h3 className="heading3 text-black">{quote}</h3>
          <p className="mt-4">{quoteAuthor}</p>
        </div>
      </div>
      <div className="col-12 mt-5 sardar_patel" data-aos="fade-in"
          data-aos-offset="300"
          data-aos-duration="600"
          data-aos-once="true"
          data-aos-easing="ease-in-sine">
      {story.map((paragraph, index) => (
          <p key={index} dangerouslySetInnerHTML={{ __html: paragraph }}/>
        ))}
      </div>
    </WebContainer>
  );
};

const OurStoryPage = ({ Data }) => {
  const story = [
    "<strong>Dr B.S. Chopra and Dr S.K. Sharma</strong> wanted to create a medical institute that would one day become a leading multispecialty hospital. Committed to the core Indian values of selfless care and customer delight, they simultaneously sought to bring the highest standards of expertise and medical excellence to their community.",
    "Built from the bottom up by two visionary desi boys on a mission, Patel Hospital has grown up from the soil to become a renowned centre of medical excellence, equal to meet every healthcare need with world-class treatment. Our founding doctors’ unique vision, dedication, and medical skills guided Patel Hospital to the heights it has achieved today.",
    "From 20 beds to 250+ beds, from a small hospital in Jalandhar to a leading multispecialty medical centre in North West India, it has indeed been a journey of greatness. Proving that where integrity and patient care are prioritized, excellence will follow!",
    "Over the years, as technology evolved at a dizzying pace and medical innovations transformed the arena of medicine, Patel Hospital stayed abreast of the latest trends, always striving to deliver world-class medicine to patients.",
    "Patel Hospital provides international-level infrastructure, the latest technology, and cutting-edge procedures for a vast range of specialties. Whether you are looking for a routine health check-up, diagnostic services, consultation, specialized expertise, world-class surgical options, or the latest medical technology, at Patel Hospital, we offer it all. With us, you are in the trusted hands of top class medical experts, committed to providing you with the most efficient, dedicated, and caring patient experience."  ];

  return (
    <OurStory
      firstHeading={Data.story_heading}
      secondHeading={Data.story_title}
      description={Data.story_desc}
      imagePath={Data.story_image}
      imageAlt={Data.story_quote_name}
      quote={Data.story_quote}
      quoteAuthor={Data.story_quote_name}
      story={story}
    />
  );
};

export default OurStoryPage;
