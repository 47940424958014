import React, { useState } from "react";
import ImageTag from "../ImageTag/Index";
import classNames from "classnames";
import "../Tabs/Tabs.css";

const Horizontaltabs = ({ treatments }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const formatContent = (content) => {
    const isHTML = /<\/?[a-z][\s\S]*>/i.test(content);

    if (isHTML) {
      return content;
    }

    return content
      .split("\n")
      .filter((line) => line.trim() !== "") 
      .map((line, index) => `<p key=${index}>${line}</p>`)
      .join("");
  };

  return (
    <>
      <div className="row">
        <div className="accordiontitle col-lg-3 col-12">
          <ul>
            {treatments.map((treatment, index) => (
              <li
                key={index}
                onClick={() => setActiveIndex(index)}
                className={classNames({ active: activeIndex === index })}
              >
                {treatment.title}
              </li>
            ))}
          </ul>
        </div>
        <div className="accordiontabchild d-flex flex-wrap align-items-center col-lg-9 position-relative">
          {treatments.map((treatment, index) => (
            <div
              key={index}
              className={classNames(
                "row accordionposition d-flex justify-content-start align-items-center w-100",
                {
                  active: activeIndex === index,
                  hidden: activeIndex !== index,
                }
              )}
            >
              <div className="accordionimg col-lg-5 col-12">
                <span className="flex-center">
                  <ImageTag ImagePath={treatment.image} />
                </span>
              </div>
              <div className="accordioncont col-lg-7 col-12">
                <div
                  className="col-12 float-start"
                  dangerouslySetInnerHTML={{
                    __html: formatContent(treatment.content),
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Horizontaltabs;
