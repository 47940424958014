import React from 'react'

export const Twitter = (props) => {
  return (
    <>
    <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 800 800"
    {...props}
  >
    <defs>
      <style>{".cls-1{fill:#000;stroke-width:0}"}</style>
    </defs>
    <path
      d="M400-.88C178.97-.88-.88 178.97-.88 400S178.97 800.87 400 800.87 800.87 621.02 800.87 400 621.02-.88 400-.88Zm0 775c-206.3 0-374.13-167.82-374.13-374.13S193.7 25.87 400 25.87 774.13 193.7 774.13 400 606.31 774.13 400 774.13Z"
      className="cls-1"
    />
    <path
      d="M562.21 552.44c-.43-.67-.79-1.27-1.12-1.8-.64-1.04-1.15-1.87-1.74-2.67l-16.79-22.42c-35.34-47.19-71.89-95.99-107.96-143.9-2.65-3.52-2.57-5.14.4-8.29 29.51-31.3 59.37-63.26 88.25-94.17l17.5-18.73c2.47-2.64 4.85-5.29 7.62-8.36 1.37-1.53 2.83-3.14 4.41-4.89l1.52-1.67h-2.26c-2.56 0-4.96-.03-7.25-.06-5.38-.07-10.02-.14-14.72.23-2.88.23-6.79 1.53-9.06 3.92-18.42 19.45-37.01 39.34-54.99 58.58-6.77 7.24-13.53 14.48-20.3 21.72-6.33 6.77-12.59 13.38-19.21 20.38-2.69 2.85-5.41 5.72-8.16 8.63-.26-.28-.5-.55-.74-.81-.85-.93-1.58-1.73-2.21-2.56l-8.43-11.24c-22.98-30.63-46.75-62.31-70.02-93.54-2.87-3.85-5.9-5.43-10.38-5.37-19.55.15-39.43.13-58.66.11-8.28 0-16.56-.02-24.84-.01-.89 0-1.77.15-2.79.31-.52.09-1.1.18-1.76.27l-1.58.21.88 1.33c.41.62.77 1.18 1.1 1.69.62.98 1.17 1.82 1.76 2.61l19.29 25.8c33.17 44.37 67.46 90.26 101.34 135.28 2.59 3.44 2.1 5.06-.57 7.89-30.25 32.06-60.93 64.74-90.6 96.34l-11.78 12.55c-4.57 4.87-9.11 9.76-13.65 14.66-2.12 2.28-4.23 4.56-6.35 6.83l-.47.51 1.29 2.55h.62c1.76 0 3.53-.04 5.3-.08 3.91-.09 7.96-.18 11.86.2.61.06 1.2.09 1.77.09 5.83 0 9.47-2.99 12.52-6.29 26.51-28.6 53.6-57.55 79.8-85.55l9.11-9.74c3.99-4.27 7.98-8.4 12.19-12.78 1.69-1.76 3.4-3.53 5.12-5.32.25.28.49.54.72.8.81.89 1.44 1.59 2 2.33l11.39 15.22c23.57 31.49 47.95 64.04 71.81 96.15 2.67 3.6 5.51 5.08 9.78 5.08h.11c19.18-.12 38.69-.09 57.56-.08 8.26 0 16.53.01 24.79.01 1.14 0 2.28-.11 3.6-.23.68-.06 1.42-.13 2.25-.2l1.66-.13-.89-1.41Zm-41.81-21.1c-.09.02-.17.05-.26.07-.89.24-1.59.43-2.23.43-2.36.01-4.71.03-7.07.04-9.27.07-18.85.13-28.25-.2-2.25-.08-5.33-1.71-6.72-3.55-33.71-44.62-67.84-90.11-100.85-134.09l-16.43-21.89c-25.26-33.65-50.51-67.3-75.76-100.96-.53-.71-1.02-1.5-1.7-2.59-.15-.24-.31-.5-.48-.77 2.94 0 5.82 0 8.66-.01 10.87-.02 21.13-.04 31.38.16 1.3.02 3.19 1.49 4.2 2.83 22.87 30.37 46.07 61.29 68.5 91.2a157862.573 157862.573 0 0 0 77.98 103.87c13.18 17.55 26.36 35.09 39.54 52.65 2.26 3 4.48 6.03 6.85 9.25.86 1.16 1.73 2.35 2.63 3.58Z"
      className="cls-1"
    />
  </svg>
    </>
  )
}
