import React, { useEffect, useState } from "react";
import WebContainer from "../WebContainer/Index";
import Title from "../Title/Index";
import ImageTag from "../ImageTag/Index";
import "../Card/ServiceCard.css";
import Fill from "../Button/Fill/Index";
import { NavLink } from "react-router-dom";

const ServiceCard = ({
  index,
  firstHeading,
  secondHeading,
  description,
  serviceImage,
  Link,
}) => {
  const [isZoomed, setIsZoomed] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(false);

  // Toggle zoom effect
  useEffect(() => {
    const interval = setInterval(() => {
      setIsZoomed((prevState) => !prevState);
    }, 8000);

    return () => clearInterval(interval);
  }, []);

  // Count words in the description
  const countWords = (text) => {
    return text.trim().split(/\s+/).length;
  };

  // Determine if the description is too long
  const isDescriptionLong = countWords(description) > 75;

  // Toggle full description view
  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  // Conditionally render the description
  const renderDescription = (text, limit) => {
    const words = text.trim().split(/\s+/);
    if (words.length > limit) {
      return words.slice(0, limit).join(" ") + "...";
    }
    return text;
  };

  const parentClass =
    index % 2 === 0 ? "ServiceCard p-100" : "ServiceCard gray-background p-100";
  const directionClass =
    index % 2 === 0
      ? "row justify-content-between"
      : "row justify-content-between flex-row-reverse";

  const renderedDescription = showFullDescription
    ? description
    : renderDescription(description, 75);

  return (
    <div
      className="col-12 float-start"
      data-aos="fade-in"
      data-aos-offset="200"
      data-aos-duration="600"
      data-aos-once="true"
      data-aos-easing="ease-in-sine"
    >
      <WebContainer _parentClass={parentClass}>
        <div
          className="col-12 float-start"
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-duration="500"
          data-aos-once="true"
          data-aos-easing="ease-in-sine"
        >
          <div className={directionClass}>
            <div className="col-lg-7 col-12">
              <Title
                firstHeading={firstHeading}
                secondHeading={secondHeading}
                _class={"text-lg-start mb-3"}
                _classh4={"removeBand m-0"}
              />
              <div className="col-lg-4 col-6 col-12 overflow-hidden mobile-show text-center">
                <ImageTag
                  ImagePath={serviceImage}
                  ImageAlt={"Service Image"}
                  ImageClass={`${isZoomed ? "zoom-in" : "zoom-out"} my-4`}
                />
              </div>
              <div className="serviceCarddesc">
                <div
                  dangerouslySetInnerHTML={{
                    __html: description || "No description available",
                  }}
                />

                {Link !== "" && (
                  <>
                    {" "}
                    <NavLink to={Link}>
                      <span class=" col-12 mt-5 button-container-1 secondarybtn primary-color cursor-pointer custom-btn-hover">
                        Read More
                      </span>
                    </NavLink>
                    {/* <span className="mt-5">
                      <Fill
                        buttonText1="Read More"
                        buttonText2="Read More"
                        _linkClass="mt-5 "
                        _class="secondarybtn mt-3"
                        pageLink={Link}
                      />
                    </span> */}
                  </>
                )}
                {/* {description && (
                  <span
                    className=" col-12 mt-5 button-container-1 secondarybtn primary-color cursor-pointer custom-btn-hover"
                    onClick={toggleDescription}
                  >
                    {showFullDescription ? 'Read Less' : 'Read More'}
                    {description}
                  </span>
                )} */}
              </div>
            </div>
            <div className="col-lg-4 col-6 col-12 overflow-hidden desktop-show">
              <ImageTag
                ImagePath={serviceImage}
                ImageAlt={"Service Image"}
                ImageClass={`${isZoomed ? "zoom-in" : "zoom-out"}`}
              />
            </div>
          </div>
        </div>
      </WebContainer>
    </div>
  );
};

export default ServiceCard;
