import React from 'react';
import PropTypes from 'prop-types';
import ImageTag from '../ImageTag/Index';

const HearFelt = ({ imagePath, imageAlt, headingText }) => {
  return (
    <div className='col-lg-6 col-12'>
      <div className='heartfelt'>
        <ImageTag ImagePath={imagePath} ImageAlt={imageAlt} />
        <div className='blue__circle_heading'>
          <h4 className='text-uppercase' dangerouslySetInnerHTML={{ __html: headingText }} />
        </div>
      </div>
    </div>
  );
};

HearFelt.propTypes = {
  imagePath: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired,
  headingText: PropTypes.string.isRequired,
};

export default HearFelt;
