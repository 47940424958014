import React from "react";
import WebContainer from "../WebContainer/Index";
import Title from "../Title/Index";
import Circle from "../Circle/Circle";
import Coesvg from "../../images/Vectors/Coesvg";

const WhyPatelHospital = ({ Data }) => {
  const items = Data.cardData.map((item) => ({
    icon: (
      <Coesvg Data={item.path} themeColor={item.color} viewBox={item.viewBox} />
    ),
    text: item.cardText,
  }));

  return (
    <WebContainer _parentClass="why_Patel">
      <Title firstHeading={"WHY PATEL HOSPITAL?"} secondHeading={Data.title} headingThree={true}/>
      <Circle items={items} />
    </WebContainer>
  );
};

export default WhyPatelHospital;
