import React from 'react';
import ImageTag from '../ImageTag/Index';
import Coesvg from '../../images/Vectors/Coesvg'


const Index = ({ icon_path, color, viewbox, cardText, cardImage, _cardClass, _cardP, cardImagewidth, cardImageHeight, cardCont, cardHeading, cardBox, childCard }) => {
  return (
    <div className={`cardParent cardCoe radius-25 d-lg-flex flex-center p-3 ${_cardClass}`}>
     
       <div className="createImage col-lg-4 col-12">        
          <div className='svgIconCOE'>
          {icon_path ? (
         <>
          <Coesvg
            Data={icon_path}
            themeColor={color}
            viewBox={viewbox}
          />
          <svg>
          <defs>
            <linearGradient id="grad1" gradientTransform="rotate(80)">
              <stop offset="30%" style={{ stopColor: 'var(--primary)' }} />
              <stop offset="70%" style={{ stopColor: 'var(--secondary)' }} />
            </linearGradient>
          </defs>     
        </svg>
         </>
          ) : ( <span className='flex-center h-100 gray-background radius-25'><small className='opacity-50'>no icon</small></span> ) }
          </div>
       </div>
      {cardText && <p className={`${_cardP}`}><strong>{cardText}</strong></p>}
      {cardHeading && 
      <div className={`col-lg-8 col-12 px-2 ${cardBox}`}>
        <div className={`${childCard}`}>
        <span className='text-black text-uppercase'>{cardCont}</span>
        {/* <h3 className='m-0'>{cardHeading}</h3>         */}
        </div>
      </div>} 
    </div>
  );
};

export default Index;
