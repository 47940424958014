import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Banner from "./components/Banner/Index";
import ServiceCard from "./components/Card/ServiceCard";
import { BASE_URL } from "./Utils/useApi";
import "./components/Card/ServiceCard.css";
import WebContainer from "./components/WebContainer/Index";
import { useLocation } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import Error from "./Error";

const Services = () => {
  const [servicesData, setServicesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [headings, setHeadings] = useState([]);
  const [activeHeadingIndex, setActiveHeadingIndex] = useState(null);
  const [scrollClass, setNavbar] = useState(false);
  const sectionRefs = useRef([]);
  const location = useLocation();
  const navbarRef = useRef(null); 

  useEffect(() => {
    const fetchServicesData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/services.php`);
        const servicesArray = response.data;
        setServicesData(servicesArray);

        const extractedHeadings = servicesArray.services.map(
          (service) => service.heading
        );
        setHeadings(extractedHeadings);
        
        sectionRefs.current = servicesArray.services.map(
          (_, i) => sectionRefs.current[i] ?? React.createRef()
        );
        
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };
    console.log("ths is the heading",headings);

    fetchServicesData();
  }, []);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const index = sectionRefs.current.findIndex(
            (ref) => ref.current === entry.target
          );
          setActiveHeadingIndex(index);
        }
      });
    }, observerOptions);

    sectionRefs.current.forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      sectionRefs.current.forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, [sectionRefs, servicesData]);

  useEffect(() => {
    const changeBackground = () => {
      if (window.scrollY >= 600) {
        setNavbar(true);
      } else {
        setNavbar(false);
      }
    };

    window.addEventListener("scroll", changeBackground);

    return () => {
      window.removeEventListener("scroll", changeBackground);
    };
  }, []);

  // Scroll to the section specified in the hash after data is loaded
  useEffect(() => {
    if (!loading && location.hash) {
      const sectionId = location.hash.replace("#", "");
      const sectionElement = document.getElementById(sectionId);
      if (sectionElement) {
        const navbarHeight = navbarRef.current?.clientHeight || 0; // Reference navbar height
        const elementPosition =
          sectionElement.getBoundingClientRect().top +
          window.pageYOffset -
          navbarHeight;
        window.scrollTo({
          top: elementPosition,
          behavior: "smooth",
        });
      }
    }
  }, [loading, location]);

  const handleScrollToSection = (index) => {
    const sectionRef = sectionRefs.current[index];
    if (sectionRef && sectionRef.current) {
      const navbarHeight = navbarRef.current?.clientHeight || 0; // Reference navbar height
      const elementPosition =
        sectionRef.current.getBoundingClientRect().top +
        window.pageYOffset -
        navbarHeight -
        50; // Adding extra buffer
      window.scrollTo({
        top: elementPosition,
        behavior: "smooth",
      });
      setActiveHeadingIndex(index);
    }
  };
  if (loading)
    return (
      <div className="preloader flex-center">
        <CircularProgress size={24} />
      </div>
    );
    if (!servicesData)
      return (
        <Error />
      );
  if (error) return <div className="error">{error}</div>;

  return (
    <main className="col-12 float-start gap-0" data-scroll-container>
      <Banner
        
        BannerImage={servicesData.homeBanner.imagePath}
        bannerContent={servicesData.homeBanner.heading}
        rightPosition={'shiftPosition'}
        BannerName={"About Patel Hospital"}
        pageName={"Services"}
        
      />

      <WebContainer _parentClass={scrollClass ? "fixmenu show" : "fixmenu"}>
        <div
          className={`col-12 desktop-show float-start ${
            scrollClass ? "scrollClass sticky" : "scrollClass"
          }`}
        >
          <ul className="d-flex border-top border-bottom justify-content-center">
            {headings.map((heading, index) => (
              <li
                key={index}
                onClick={() => handleScrollToSection(index)}
                className={`cursor-pointer ${
                  activeHeadingIndex === index ? "active" : ""
                }`}
              >
                {heading}
              </li>
            ))}
          </ul>
        </div>
      </WebContainer>

      {servicesData.services.map((data, index) => (
        <div
          id={data.heading.toLowerCase().replace(/\s+/g, "-")}
          key={index}
          ref={sectionRefs.current[index]}
          className="col-12 float-start"
        >
          <ServiceCard
            index={index}
            firstHeading={data.heading}
            secondHeading={data.title}
            description={data.description}
            serviceImage={data.image}
            Link={data.link}
          />
        </div>
      ))}
    </main>
  );
};

export default Services;
