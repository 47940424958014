import React from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import WebContainer from "../WebContainer/Index";
import Title from "../Title/Index";
import Image from "../ImageTag/Index";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NavLink } from "react-router-dom";

const formatContent = (content) => {
  const isHTML = /<\/?[a-z][\s\S]*>/i.test(content);
  if (isHTML) {
    return content;
  }
  return content
    .split("\n")
    .filter((line) => line.trim() !== "")
    .map((line, index) => `<p key=${index}>${line}</p>`)
    .join("");
};

const SpecialityCard = ({ speciality }) => (
  <div
    data-aos="fade-in"
    data-aos-offset="200"
    data-aos-duration="500"
    data-aos-once="true"
    data-aos-easing="ease-in-sine"
    className="col-lg-10 m-auto col-12 text-lg-custom-start"
  >
    <Image
      ImagePath={speciality.image}
      ImageAlt={speciality.title}
      ImageClass="m-auto"
    />
    <h4 className="heading5 fw-400 text-uppercase secondary-color">
      {speciality.title}
    </h4>
    <div className="col-12 float-start specilitiesSlide">
      <div
        dangerouslySetInnerHTML={{
          __html: formatContent(speciality.content),
        }}
      />
    </div>
    {speciality.link && (
      <NavLink
        className="d-inline-block"
        to={`/centres-of-excellence/${speciality.link}`}
      >
        <span className="col-12 mt-4 button-container-1 secondarybtn primary-color cursor-pointer custom-btn-hover">
          Know More
        </span>
      </NavLink>
    )}
  </div>
);

const Specialities = ({ Data }) => {
  if (!Data || !Data.Data) {
    return null;
  }

  const showArrows = Data.Data.length > 2;

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: showArrows,
    responsive: [
      {
          breakpoint: 768,
          settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: Data.Data.length > 1,
        },
      },
    ],
  };

  return (
    <WebContainer _parentClass="specialities">
      <Title
        firstHeading="SPECIALTIES"
        secondHeading={Data.title}
        headingThree={true}
      />
      <div className="col-12 m-auto slider doctors">
        <Slider {...settings}>
          {Data.Data.map((speciality, index) => (
            <div key={index} className="col-lg-6 col-12">
              <SpecialityCard speciality={speciality} />
            </div>
          ))}
        </Slider>
      </div>
    </WebContainer>
  );
};

Specialities.propTypes = {
  Data: PropTypes.shape({
    title: PropTypes.string,
    Data: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        link: PropTypes.string,
        content: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default Specialities;
