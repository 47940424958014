import React, { useEffect, useState } from "react";
import { FooterCTA } from "./FooterCTA";
import "../Footer/Footer.css";
import Container from "../Container/Index";
import { Facebook } from "../../images/Vectors/Facebook";
import { Twitter } from "../../images/Vectors/Twitter";
import Youtube from "../../images/Vectors/Youtube";
import { LinkedIn } from "../../images/Vectors/LinkedIn";
import { Link, NavLink, useLocation } from "react-router-dom";
import Mail from "../../images/Vectors/Mail";
import Location from "../../images/Vectors/Location";
import Call from "../../images/Vectors/Call";
import ImageTag from "../../components/ImageTag/Index";
import ImagePath from "../../images/excellenceLogo.webp";
import axios from "axios";
import { BASE_URL } from "../../Utils/useApi";

const Index = () => {
  const year = new Date().getFullYear();
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const sectionId = location.hash.substring(1);
      const sectionElement = document.getElementById(sectionId);
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [location.hash]);

  const handleScrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  const formatPhoneNumber = (input) => {
    // Return early if input is undefined or null
    if (!input) return "";

    return input.replace(/(\+91-\(0\)-|[-\s()])/g, "");
  };

  const [coexSubmenus, setCoexSubmenus] = useState([]);
  const [contactData, setcontactData] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/menu.php`);
        if (response.data.error) {
          setError(response.data.error);
        } else {
          setCoexSubmenus(response.data.navigation);
          setcontactData(response.data.contact_details);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const excellence = coexSubmenus.map((item) => ({
    text: item.category,
    url: item.activedata ? `/centres-of-excellence/${item.slug}` : null,
    url2: item.url ? `${item.url}` : null,
  }));

  return (
    <>
      <footer className="col-12 float-start">
        <FooterCTA Data={contactData} />
        <Container _parentClass="p-100 pb-0">
          <div
            className="col-12"
            data-aos="fade-in"
            data-aos-offset="200"
            data-aos-duration="500"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="footerbox col-12 float-start">
              <div className="col-12 float-start d-flex justify-content-between flex-wrap ">
                <div className="footercolumn">
                  <h3 className="text-black fw-600">For Patient</h3>
                  <ul>
                    <li>
                      <NavLink to="/">Home</NavLink>
                    </li>
                    <li>
                      <NavLink to="/doctors">Find a Doctor</NavLink>
                    </li>
                    <li>
                      <NavLink to="/book-an-appointment">
                        Book An Appointment
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/download-reports">Download Reports</NavLink>
                    </li>
                    <li>
                      <NavLink to="/patient-room">Patient Rooms</NavLink>
                    </li>
                    <li>
                      <NavLink to="/cashless-service">Cashless Service</NavLink>
                    </li>
                    <li>
                      <NavLink to="/testimonials">Testimonials</NavLink>
                    </li>
                    <li>
                      <a>Blog</a>
                    </li>
                  </ul>
                </div>
                <div className="footercolumn">
                  <h3 className="text-black fw-600">Centres of Excellence</h3>
                  <ul>
                    {excellence.map((excellence, index) => (
                      <li key={index}>
                        {excellence.url ? (
                          <Link to={excellence.url}>{excellence.text}</Link>
                        ) : excellence.url2 ? (
                          <a href={excellence.url2} target="_blank">
                            {excellence.text}
                          </a>
                        ) : (
                          <>{excellence.text}</>
                        )}
                      </li>
                    ))}
                    <li>
                      <Link to="/centres-of-excellence">View More</Link>
                    </li>
                  </ul>
                </div>
                <div className="footercolumn">
                  <h3 className="text-black fw-600"> Services</h3>
                  <ul>
                    <li>
                      <NavLink to="/services#ambulance">Ambulance</NavLink>
                    </li>
                    <li>
                      <NavLink to="/services#pharmacy">Pharmacy</NavLink>
                    </li>
                    <li>
                      <NavLink to="/services#blood-bank">Blood Bank</NavLink>
                    </li>
                    <li>
                      <NavLink to="/services#lab-services">
                        Lab Services
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/services#diagnostics">Diagnostics</NavLink>
                    </li>
                    <li>
                      <NavLink to="/services#physiotherapy">
                        Physiotherapy
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/services#radiology">Radiology</NavLink>
                    </li>
                  </ul>
                </div>

                <div className="footercolumn">
                  <h3 className="text-black fw-600">Corporate</h3>
                  <ul>
                    <li>
                      <NavLink to="/about-us">About Us</NavLink>
                    </li>
                    <li>
                      <a
                        href="https://careers.patelhospital.com/jobs/Careers"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Careers
                      </a>
                    </li>
                    <li>
                      <NavLink to="/contact-us">Contact Us</NavLink>
                    </li>
                    <li>
                      <NavLink to="/media">Media</NavLink>
                    </li>
                  </ul>
                </div>
                <div className="footercolumn">
                  <h3 className="text-black fw-600">Quick Links</h3>
                  <ul>
                    <li>
                      <NavLink to="/transplant-report">
                        Transplant Reports
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/visitor-guidelines">
                        Visitor Guidelines
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/bio-medical-waste">
                        Biomedical Waste
                      </NavLink>
                    </li>
                  </ul>
                  <div className="footerbox mt-4">
                    <ImageTag ImagePath={ImagePath} />
                  </div>
                </div>
                <div className="footercolumn">
                  <div className="footerbox">
                    <ul>
                      <li className="position-relative p-0 pt-1">
                        <span>
                          <Call />
                        </span>
                        <p className="m-0">
                          <strong className="tfhours">24 hours</strong>
                          <strong>
                           <a
                              href={`tel:${
                                contactData?.header_number_phone
                                  ? formatPhoneNumber(
                                      contactData.header_number_phone
                                    )
                                  : ""
                              }`}
                              className="pt-4"
                            >
                              {contactData.header_number_phone}
                            </a>
                          </strong>
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="footerbox">
                    <ul>
                      <li>
                        <span>
                          <Mail />
                        </span>
                        <p>
                          <a href={`mailto:${contactData.email}`}>
                            {contactData.email}
                          </a>
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="footerbox">
                    <ul>
                      <li>
                        <span>
                          <Location />
                        </span>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: contactData.address,
                          }}
                        />
                      </li>
                    </ul>
                  </div>
                  <div className="footerbox follow_us">
                    <h4 className="text-uppercase fw-bold">FOLLOW US</h4>
                    <ul className="d-flex">
                      <li className="fb">
                        {contactData.social_media?.facebook && (
                          <a
                            href={contactData.social_media.facebook}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span>
                              <Facebook />
                            </span>
                          </a>
                        )}
                      </li>
                      <li className="tw">
                        {contactData.social_media?.twitter && (
                          <a
                            href={contactData.social_media.twitter}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span>
                              <Twitter />
                            </span>
                          </a>
                        )}
                      </li>
                      <li className="tel">
                        {contactData.social_media?.youtube && (
                          <a
                            href={contactData.social_media.youtube}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span>
                              <Youtube />
                            </span>
                          </a>
                        )}
                      </li>
                      <li className="li">
                        {contactData.social_media?.linkedin && (
                          <a
                            href={contactData.social_media.linkedin}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span>
                              <LinkedIn />
                            </span>
                          </a>
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 float-start d-flex justify-content-between pt-3 pb-3 mt-5 border-top copyrightcont">
            <p>
              <a>&copy; {year} | All Rights Reserved</a>
              <Link to="/privacy-policy">Privacy Policy</Link>
              <Link to="/refund-cancellation-policy">
                Refund & Cancellation Policy
              </Link>
              <Link to="/terms-conditions">Terms & Conditions</Link>
            </p>
            <p>
              <a
                href="https://triverseadvertising.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                site : triverse
              </a>
            </p>
          </div>
        </Container>
      </footer>
    </>
  );
};

export default Index;
