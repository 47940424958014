import React from 'react';
import Slider from 'react-slick';
import "./Slider.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ImageTag from '../ImageTag/Index';
import Fill from '../Button/Fill/Index';
import Circle from '../Circle/Circle';
import Quote from '../../images/Vectors/Quote';
import DownCircle from '../../images/Vectors/DownCircle';

const SlickSlider = ({
  docHeading,
  heroHeading,
  slides,
  _parentButton,
  slidesToShow = 1,
  slidesToScroll = 1,
  arrows = 1,
  slide__content,
  sliderImageWidth,
  sliderImageHeight,
  msliderImageWidth,
  msliderImageHeight,
  sliderClass,
  sliderImageClass,
  showButton,
  showCircle,
  circlePosition,
  showStar,
  onSlideClick,
  sliderChild,
  mShow,
  activeSlide,
  mobileImage,
  responsive = [],
  showContent = true,
  loop = false,
}) => {
  const infinite = loop && slides.length > slidesToShow;
  const settings = {
    infinite,
    slidesToShow,
    slidesToScroll,
    autoplaySpeed: 5000,
    speed: 1000,
    lazyLoad: 'progressive',
    arrows,
    dots: false,
    autoplay: true,
    responsive,
  };

  const items = [{ icon: <Quote /> }];

  return (
    <Slider {...settings}>
      {slides.map((slide, index) => {
        const isLastSlide = index === 0;
        return (
          <div
            key={index}
            className={`${activeSlide === index ? 'active' : ''}`}
            onClick={() => onSlideClick && onSlideClick(slide, index)}
          >
            <div
              className={`slide position-relative overflow-hidden ${sliderClass} ${isLastSlide ? 'leftPosition' : ''}`}
              data-aos="fade-in"
              data-aos-offset="200"
              data-aos-duration="600"
              data-aos-once="true"
              data-aos-easing="ease-in-sine"
            >
              {slide.imagePath && (
                <div className={sliderImageClass}>
                  <ImageTag
                    ImagePath={slide.imagePath}
                    ImageAlt={slide.imageAlt }
                    ImageWidth={sliderImageWidth}
                    ImageHeight={sliderImageHeight}
                    ImageClass={`w-100 sliderClass ${mShow} `}
                  />
                  {mobileImage && 
                  <ImageTag
                  ImagePath={slide.mobimgPath}
                  ImageAlt={slide.imageAlt || ''}
                  ImageWidth={msliderImageWidth}
                  ImageHeight={msliderImageHeight}
                  ImageClass="w-100 sliderClass mmobile-show"
                />
                  }
                </div>
              )}
              <div className={slide__content}>
                {showCircle && <Circle items={items} circleClass={circlePosition} />}
                {showStar && (
                  <div className='star'>
                    {Array.from({ length: 5 }, (_, i) => (
                      <i key={i} className='fa fa-star'></i>
                    ))}
                  </div>
                )}
                { heroHeading && slide.title && <figcaption dangerouslySetInnerHTML={{ __html: slide.title }} />}
                { docHeading && slide.title && <h3 dangerouslySetInnerHTML={{ __html: slide.title }} />}
                <div className={`col-12 float-start ${sliderChild}`}>
                  {showContent && slide.content && (
                    <p dangerouslySetInnerHTML={{ __html: slide.content }} />
                  )}
                </div>
                {showButton && (
                  <Fill
                    buttonText1="Read More"
                    buttonText2="Read More"
                    _class="secondarybtn"
                    pageLink={`/doctors/${slide.slug}`}
                  />
                )}
                {slide.content && (
                  <button className={`border-0 downarrow ${_parentButton}`}>
                    <DownCircle />
                  </button>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </Slider>
  );
};

export default SlickSlider;
