import React from "react";
import { NavLink } from "react-router-dom";
import WebContainer from './components/WebContainer/Index'
import Title from './components/Title/Index'
import Fill from './components/Button/Fill/Index'
import BreadCrumb from "./components/BreadCrumb/Index";
import Container from "./components/Container/Index";
const Error = () => {
  return (
    <>
      <Container>
              <BreadCrumb
                pageName={"404"}
              />
            </Container>
     <WebContainer>
    <div className="col-lg-6 col-12 m-auto text-center pt-5 pb-5 mt-5 mb-5">
          <Title firstHeading={'404'} secondHeading={'WE ARE SORRY, PAGE NOT FOUND!'} _class={'mb-3'}/>
          <p>The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
            <div className="flex-center">
            <Fill buttonText2={'back to Home'} buttonText1={'back'} pageLink={'/'}/>
            </div>
        </div>
   </WebContainer>
    </>
  );
};

export default Error;
