import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import WebContainer from "../WebContainer/Index";
import Title from "../Title/Index";
import ImageTag from "../ImageTag/Index";
import Outline from "../Button/Outline/Index";
import Section from "../Services/Tieups";
import Steps from "../../images/Vectors/Steps";

const steps = [
  {
    number: 1,
    title: "Provide necessary documents at the hospital",
    details: [
      "Original Health Insurance Card / Policy Document",
      "Patient Aadhaar Card / Pan Card / Voter Card / Driving Licence",
      "Proposer Photo ID Proof + Driving Licence + Voter Card + Aadhaar Card / Pan Card + Passport Size Photo",
    ],
  },
  {
    number: 2,
    title: "Submit/sign the pre-authorization form at the TPA desk.",
    details: [],
  },
  {
    number: 3,
    title:
      "On completion, the hospital will submit the pre-authorisation form to the TPA/Insurer Company.",
    details: [],
  },
  {
    number: 4,
    title:
      "On discharge, patient/proposer will sign the required document at the TPA desk for final cashless approval.",
    details: [],
  },
];

const medicalUrgency = {
  title: "Medical Urgency",
  subtitle: "IN CASE OF EMERGENCY",
  descp:
    "For cashless hospitalization, it is mandatory that you have your approval before receiving treatment. In case of a medical emergency, the patient starts the treatment by depositing the cash. The TPA desk will try their best to fast-track your case for cashless service. Upon receiving approval from your TPA/Insurance you are entitled to a refund of that cash deposit on final approval.",
};

const CashLess = ({ Data, showButton }) => {
  console.log("data os te", Data);
  const location = useLocation();
  const isHomePage = location.pathname === "/";
  const [headings] = useState([
    "EMPANELMENTS",
    "ORGANISATIONS",
    "Cashless Process",
  ]);
  const [loading, setLoading] = useState(true);
  const [activeHeadingIndex, setActiveHeadingIndex] = useState(null);
  const [scrollClass, setNavbar] = useState(false);
  const sectionRefs = useRef(headings.map(() => React.createRef()));
  const navbarRef = useRef(null);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const index = sectionRefs.current.findIndex(
            (ref) => ref.current === entry.target
          );
          if (index !== -1) {
            setActiveHeadingIndex(index);
          }
        }
      });
    }, observerOptions);

    sectionRefs.current.forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      sectionRefs.current.forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, []);

  useEffect(() => {
    const changeBackground = () => {
      if (window.scrollY >= 200) {
        setNavbar(true);
      } else {
        setNavbar(false);
      }
    };

    window.addEventListener("scroll", changeBackground);

    return () => {
      window.removeEventListener("scroll", changeBackground);
    };
  }, []);

  useEffect(() => {
    if (!loading && location.hash) {
      setTimeout(() => {
        const sectionId = location.hash.replace("#", "");
        const sectionElement = document.getElementById(sectionId);
        if (sectionElement) {
          const navbarHeight = navbarRef.current?.clientHeight || 0;
          const elementPosition = sectionElement.offsetTop;
          window.scrollTo({
            top: elementPosition,
            behavior: "smooth",
          });
        }
      }, 100); // Small delay to ensure DOM is ready
    }
    setLoading(false);
  }, [loading, location, navbarRef]);

  const handleScrollToSection = (index) => {
    const sectionRef = sectionRefs.current[index];
    if (sectionRef && sectionRef.current) {
      const navbarHeight = navbarRef.current?.clientHeight || 0;
      const elementPosition = sectionRef.current.offsetTop;
      window.scrollTo({
        top: elementPosition,
        behavior: "smooth",
      });
      setActiveHeadingIndex(index);
    }
  };

  return (
    <>
      {!isHomePage && (
        <WebContainer _parentClass={scrollClass ? "fixmenu show" : "fixmenu"}>
          <div
            className={`col-12 desktop-show float-start ${
              scrollClass ? "scrollClass sticky" : "scrollClass"
            }`}
            ref={navbarRef}
          >
            <ul className="d-flex border-top border-bottom justify-content-center">
              {headings.map((heading, index) => (
                <li
                  key={index}
                  onClick={() => handleScrollToSection(index)}
                  className={`cursor-pointer ${
                    activeHeadingIndex === index ? "active" : ""
                  }`}
                >
                  {heading}
                </li>
              ))}
            </ul>
          </div>
        </WebContainer>
      )}
      <WebContainer _parentClass="cashLess position-relative">
        {!isHomePage && (
          <>
            <div className="col-12 float-start " ref={sectionRefs.current[0]}>
              <div className="col-lg-10 col-sm-12 col-12 m-auto tpaBranches">
                <div className="row">
                  <Title
                    firstHeading={"Cashless Service"}
                    // secondHeading={Data.title}
                    secondHeading={"Enjoy Seamless Healthcare"}
                    headingOne={true}
                    _class={"mb-3"}
                  />
                  <Section
                    offsetTitle={"EMPANELMENTS"}
                    items={Data.cashlessDataWithoutLogo}
                  />
                </div>
              </div>
            </div>
          </>
        )}
        <div
          className="col-12 float-start position-relative mt-5"
          ref={sectionRefs.current[1]}
        >
          {!isHomePage && (
            <div className="tpaBranches mb-5">
              <h2>ORGANISATIONS</h2>
            </div>
          )}
          {isHomePage && (
            <div className="col-12 float-start " ref={sectionRefs.current[0]}>
              <div className="col-lg-10 col-sm-12 col-12 m-auto tpaBranches">
                <div className="row">
                  <Title
                    firstHeading={"Cashless Service"}
                    // secondHeading={Data.title}
                    secondHeading={"Enjoy Seamless Healthcare"}
                    headingOne={true}
                    _class={"mb-3"}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="row">
            <ul className="flex-center serviceProvider mt-3">
              {Data.cashlessData.map((item, index) => (
                <li
                  key={index}
                  className="flex-center"
                  data-aos="fade-in"
                  data-aos-offset="100"
                  data-aos-duration="500"
                  data-aos-once="true"
                  data-aos-easing="ease-in-sine"
                >
                  <p className="cashless_logo_box">
                    <ImageTag
                      ImagePath={item.imagePath}
                      ImageAlt={item.imageAlt || "Logo"}
                    />
                  </p>
                  <span>{item.imageAlt}</span>
                </li>
              ))}
            </ul>
            {showButton && (
              <div className="col-12 float-start mt-5 flex-center">
                <Outline
                  buttonText="View All"
                  _class="secondarybtn mt-5"
                  pageLink="/cashless-service"
                />
              </div>
            )}
          </div>
        </div>
        {!isHomePage && (
          <>
            <div
              className="position-relative col-12 float-start mt-5"
              ref={sectionRefs.current[2]}
            >
              <div className="tpaBranches mb-5">
                <h2>STEPS TO AVAIL CASHLESS TREATMENT</h2>
              </div>
            </div>
            <div className="col-12 float-start mb-5">
              <div className="col-lg-10 col-12 m-auto mt-5 position-relative CashlessSteps">
                {Data.steps.map((step, index) => (
                  <div
                    key={index}
                    id="steps-to-avail-cashless"
                    className="col-12 position-relative stepsCard"
                    data-aos="fade-in"
                    data-aos-offset="100"
                    data-aos-duration="500"
                    data-aos-once="true"
                    data-aos-easing="ease-in-sine"
                  >
                    <span className="stpesVector desktop-show">
                      <Steps />
                    </span>
                    <div className="stepCard flex-center position-relative col-12 float-start">
                      <div className="row col-12 float-start">
                        <div
                          className={`col-lg-3 col-12 ${
                            index % 2 === 1 ? "flex-row-reverse" : ""
                          }`}
                        >
                          <span className="numberSteps">{step.number}</span>
                        </div>
                        <div className="col-lg-9 col-12 mt-5 position-relative">
                          <h3>{step.title}</h3>
                          {step.details.length > 0 && (
                            <ul>
                              {step.details.map((detail, i) => (
                                <li key={i}>{detail}</li>
                              ))}
                            </ul>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-12 float-start mb-5 medicalUrgency mt-5">
              <Title
                firstHeading={Data.medicalUrgency.title}
                secondHeading={Data.medicalUrgency.subtitle}
                _class={"mb-3"}
              />
              <div
                className="col-lg-9 col-12 m-auto text-center"
                data-aos="fade-in"
                data-aos-offset="100"
                data-aos-duration="500"
                data-aos-once="true"
                data-aos-easing="ease-in-sine"
              >
                <p>{Data.medicalUrgency.description}</p>
              </div>
            </div>
          </>
        )}
      </WebContainer>
    </>
  );
};

export default CashLess;
