import React, { useEffect, useState } from 'react';
import axios from "axios";
import { BASE_URL } from "../../Utils/useApi";
import WebContainer from '../WebContainer/Index';
import Appoinment from '../../images/Vectors/Appoinment';
import Call from '../../images/Vectors/Call';
import HomeCollection from '../../images/Vectors/HomeCollection';

const formatPhoneNumberForTel = (phoneNumber) => {
  return phoneNumber.replace(/(\+91-\(0\)-|[-\s()])/g, '');
};

export const FooterCTA = () => {
  const [contactDetails, setContactDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/menu.php`);
        if (response.data.error) {
          setError(response.data.error);
        } else {
        setContactDetails(response.data.contact_details);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <p></p>;
  }
  if (!contactDetails) {
    return (
        <div className="mx-auto p-100 w-100 d-flex justify-content-center">
            No data found
        </div>
    );
}
  if (error) {
    return <p>Error loading contact details: {error.message}</p>;
  }

  return (
    <WebContainer _parentClass='ctasection'>
      <div className='col-12 flaot-start' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
        <div className='row flex-center'>
          {contactDetails.footer_numbers[0].title &&
          <div className='col-lg-4 col-sm-6 col-12'>
            <div className='ctabtn flex-center'>
              <div className='ctacomponent flex-center'>
                <Appoinment />
              </div>
              <div className='ctacont'>
                <span className='opacity-50'>{contactDetails.footer_numbers[0].title}</span>
                <p>
                  <a href={`tel:${formatPhoneNumberForTel(contactDetails.footer_numbers[0].number)}`} aria-label={`Call for ${contactDetails.footer_numbers[0].title}`}>
                    {contactDetails.footer_numbers[0].number}
                  </a>
                </p>
              </div>
            </div>
          </div>
          }
          {contactDetails.footer_numbers[1].title && 
          <div className='col-lg-4 col-sm-6 col-12'>
            <div className='ctabtn flex-center'>
              <div className='ctacomponent flex-center'>
                <HomeCollection />
              </div>
              <div className='ctacont'>
                <span className='opacity-50'>{contactDetails.footer_numbers[1].title}</span>
                <p>
                  <a href={`tel:${formatPhoneNumberForTel(contactDetails.footer_numbers[1].number)}`} aria-label={`Call for ${contactDetails.footer_numbers[1].title}`}>
                    {contactDetails.footer_numbers[1].number}
                  </a>
                </p>
              </div>
            </div>
          </div>
          }
          {contactDetails.footer_numbers[2].title && 
          <div className='col-lg-4 col-sm-6 col-12'>
            <div className='ctabtn flex-center'>
              <div className='ctacomponent flex-center callSize'>
                <Call />
              </div>
              <div className='ctacont'>
                <span className='opacity-50'>{contactDetails.footer_numbers[2].title}</span>
                <p>
                  <a href={`tel:${formatPhoneNumberForTel(contactDetails.footer_numbers[2].number)}`} aria-label={`Call for ${contactDetails.footer_numbers[2].title}`}>
                    {contactDetails.footer_numbers[2].number}
                  </a>
                </p>
              </div>
            </div>
          </div>
          }
        </div>
      </div>
    </WebContainer>
  );
}
