import { useState, useEffect } from "react";
import WebContainer from "../WebContainer/Index";
import Title from "../Title/Index";
import ImageTag from "../ImageTag/Index";
import { useLocation, Link } from 'react-router-dom';

const Overview = ({ Data, rowClass, floatDirection }) => {
  const [isZoomed, setIsZoomed] = useState(false);
  const [showFullText, setShowFullText] = useState(false);
  const location = useLocation();
  const packagesButton = location.pathname.includes('health-check-up-packages');

  useEffect(() => {
    const interval = setInterval(() => {
      setIsZoomed((prevState) => !prevState);
    }, 8000);

    return () => clearInterval(interval);
  }, []);

  const descriptionParagraphs = typeof Data.description === 'string'
    ? Data.description.split('\n')
    : Array.isArray(Data.description)
    ? Data.description
    : [];

  const countWords = (text) => {
    return text.split(' ').filter(Boolean).length;
  };

  const totalWords = descriptionParagraphs.reduce((acc, paragraph) => acc + countWords(paragraph), 0);

  const getTruncatedText = () => {
    let wordCount = 0;
    return descriptionParagraphs.reduce((acc, paragraph) => {
      if (wordCount < 150) {
        const words = paragraph.split(' ');
        if (wordCount + words.length > 150) {
          acc.push(words.slice(0, 150 - wordCount).join(' ') + '...');
          wordCount = 150;
        } else {
          acc.push(paragraph);
          wordCount += words.length;
        }
      }
      return acc;
    }, []);
  };

  return (
    <WebContainer _parentClass={"overview"}>
      <div className="col-12 float-start" data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="500"
            data-aos-once="true"
            data-aos-easing="ease-in-sine">
        <div className={`row justify-content-between ${rowClass}`}>
          <div className="col-lg-7 width_1040 col-12">
            <Title
            firstHeading={'Overview'}
              secondHeading={Data.title}
              _class={"text-lg-custom-start mb-3"}
              _classh4={"removeBand m-0"}
            />
            <div className="col-12 mobile-show overflow-hidden text-center my-3">
          <ImageTag
              ImagePath={Data.image}
              ImageAlt={Data.title}
              ImageClass={`${isZoomed ? "zoom-in" : "zoom-out"} ${floatDirection}`}
            />
          </div>
           {(showFullText ? descriptionParagraphs : getTruncatedText())
  .filter(paragraph => paragraph.trim() !== '')
  .map((paragraph, index) => (
    <p key={index} dangerouslySetInnerHTML={{__html: paragraph}}/>
))}
  {packagesButton && 
      <Link
        className="d-inline-block"
        to={`/centres-of-excellence/lab-transfusion`} >
        <span className="col-12 mt-4 button-container-1 secondarybtn primary-color cursor-pointer custom-btn-hover">
          Back
        </span>
      </Link>
      }
   
            {totalWords > 150 && (           
               <span onClick={() => setShowFullText((prev) => !prev)} className="col-12 button-container-1 secondarybtn primary-color cursor-pointer custom-btn-hover"> {showFullText ? 'Show Less' : 'Know More'}</span>
            )}
          </div>
          <div className="col-lg-4 col-12 desktop-show overflow-hidden">
          <ImageTag
              ImagePath={Data.image}
              ImageAlt={Data.title}
              ImageClass={`${isZoomed ? "zoom-in" : "zoom-out"} ${floatDirection}`}
            />
          </div>
        </div>
      </div>
    </WebContainer>
  );
};

export default Overview;
