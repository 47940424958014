import React, { useEffect, useRef, useState, useMemo } from "react";
import Banner from "./components/Banner/ExcellenceBanner";
import Testimonials from "./components/Testimonials/Index";
import Overview from "./components/AboutUs/Overview";
import ScrollTabs from "./components/ScrollTabs/Index";
import Doctors from "./components/Doctors/Index";
import Specialities from "./components/Services/Specialities";
import Treatments from "./components/Services/Treatments";
import Technology from "./components/Services/Technology";
import Packages from "./components/Services/Packages";
import { useParams } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "./Utils/useApi";
import defaultImage from "./images/banner-size.webp";
import Container from "./components/Container/Index";
import BreadCrumb from "./components/BreadCrumb/Index";
import Error from "./Error";
import { CircularProgress } from "@mui/material";

const HeartCare = () => {
  const overviewRef = useRef(null);
  const storyRef = useRef(null);
  const specialtiesRef = useRef(null);
  const packagesRef = useRef(null);
  const healthyNumbersRef = useRef(null);
  const ethosRef = useRef(null);
  const testimonialsRef = useRef(null);
  const patientRef = useRef(null);

  const { slug } = useParams();
  const cleanedSlug = slug.replace(/[-_]/g, " ");

  const [pageData, setPageData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const tabs = useMemo(() => {
    if (!pageData) return [];

    const availableTabs = [];

    if (pageData.overview) {
      availableTabs.push({ name: "Overview", ref: overviewRef });
    }

    if (pageData.doctors?.doctorData?.length > 0) {
      availableTabs.push({ name: "Our Experts", ref: storyRef });
    }

    // Separate tabs for packages and specialties
    if (slug === "health-check-up-packages") {
      if (
        pageData.health_packages?.Data?.length > 0 ||
        pageData.health_packages
      ) {
        availableTabs.push({
          name: "Health Checkup Packages?",
          ref: packagesRef,
        });
      }
    } else if (pageData.specialities?.Data?.length > 0) {
      availableTabs.push({ name: "Specialties", ref: specialtiesRef });
    }

    if (pageData.treatement?.treatments?.length > 0) {
      availableTabs.push({ name: "Treatments", ref: healthyNumbersRef });
    }

    if (pageData.technologyAndFacilty?.length > 0) {
      availableTabs.push({ name: "Technology & Facilities", ref: ethosRef });
    }

    if (pageData.whypatel?.Data?.length > 0) {
      availableTabs.push({ name: "Why Patel Hospital?", ref: testimonialsRef });
    }

    if (pageData.testimonials?.testimonialsData?.length > 0) {
      availableTabs.push({ name: "Patient Stories", ref: patientRef });
    }

    return availableTabs;
  }, [pageData, slug]);

  const handleScrollTo = (ref, offset = 0) => {
    if (ref) {
      const elementPosition =
        ref.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/coe_details.php?url=${slug}`
        );
        if (response.data.error) {
          setError(response.data.error);
        } else {
          setPageData(response.data);
        }
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  if (isLoading)
    return (
      <div className="preloader flex-center">
        <CircularProgress size={24} />
      </div>
    );
  if (!pageData) return <Error />;
  if (error)
    return (
      <div className="mx-auto p-100 w-100 d-flex justify-content-center">
        Error: {error}
      </div>
    );

  return (
    <>
      <main className="col-12 float-start" data-scroll-container>
        {pageData.icon_path ? (
          <Banner
            BannerImage={pageData.banner_image}
            icon_path={pageData.icon_path}
            color={pageData.color}
            viewbox={pageData.viewbox}
            pageChildName={pageData.categoryName}
            bannerContent={pageData.bannerContent}
            BannerName={"Patel Hospital Heart Care"}
            pageName={"Centres Of Excellence"}
            pageUrl={"/centres-of-excellence"}
            _bannerHeight={"bannerHeight"}
            _bannerPath={"bgGredientlayer radius-25"}
            rightPosition={"bannerText"}
          />
        ) : (
          <Container>
            <img
              src={defaultImage}
              className="radius-25"
              alt="default banner"
            />
            <BreadCrumb
              pageName={"Centres Of Excellence"}
              pageChildName={pageData.categoryName}
              pageUrl={"/centres-of-excellence"}
            />
          </Container>
        )}

        {tabs.length > 0 && (
          <ScrollTabs
            handleScrollTo={handleScrollTo}
            refs={{
              overviewRef,
              storyRef,
              specialtiesRef,
              packagesRef,
              healthyNumbersRef,
              ethosRef,
              testimonialsRef,
              patientRef,
            }}
            tabs={tabs}
          />
        )}

        {pageData.overview && (
          <div ref={overviewRef} className="col-12 float-start">
            <Overview Data={pageData.overview} />
          </div>
        )}

        {pageData.doctors?.doctorData?.length > 0 && (
          <div ref={storyRef} className="col-12 float-start">
            <Doctors
              Data={pageData.doctors}
              firstHeading={
                pageData.doctors.doctorData.length === 1
                  ? "Meet Our Doctor"
                  : pageData.doctors.title
              }
              secondHeading={pageData.doctors.sub_title}
              HeartCare={"heart_care_slide"}
            />
          </div>
        )}

        {(pageData.health_packages?.Data?.length > 0 ||
          pageData.health_packages) && (
          <div ref={packagesRef} className="col-12 float-start">
            <Packages Data={pageData.health_packages} />
          </div>
        )}

        {pageData.specialities?.Data?.length > 0 && (
          <div ref={specialtiesRef} className="col-12 float-start">
            <Specialities Data={pageData.specialities} />
          </div>
        )}

        {pageData.treatement?.treatments?.length > 0 && (
          <div ref={healthyNumbersRef} className="col-12 float-start">
            <Treatments
              tabsData={pageData.treatement.treatments}
              snapHeading={"TREATMENTS"}
              h4Heading={pageData.treatement.title}
              _parentClass={"treatement p-100"}
              _classh4={"text-black"}
            />
          </div>
        )}

        {pageData.technologyAndFacilty?.length > 0 && (
          <div ref={ethosRef} className="col-12 float-start">
            <Technology
              Data={pageData.technologyAndFacilty}
              Data2={pageData.technologyAndFacilty_heading}
            />
          </div>
        )}

        {pageData.whypatel?.Data?.length > 0 && (
          <div ref={testimonialsRef} className="col-12 float-start">
            <Treatments
              tabsData={pageData.whypatel.Data}
              snapHeading={"Why Patel Hospital?"}
              h4Heading={pageData.whypatel.title}
              _parentClass={"why__patel centre__align-tabs"}
            />
          </div>
        )}

        {pageData.testimonials?.testimonialsData?.length > 0 && (
          <div ref={patientRef} className="col-12 float-start">
            <Testimonials
              Data={pageData.testimonials}
              snapHeading={"patient Stories"}
            />
          </div>
        )}
      </main>
    </>
  );
};

export default HeartCare;
