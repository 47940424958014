import React from 'react'
import Undermaintaince from './components/Undermaintaince/Index'

const UnderConstruction = () => {
  return (
    <>
        <Undermaintaince />
    </>
  )
}

export default UnderConstruction