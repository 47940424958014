import React, { useEffect, useState } from "react";
import WebContainer from "./components/WebContainer/Index";
import Title from "./components/Title/Index";
import Container from "./components/Container/Index";
import BreadCrumb from "./components/BreadCrumb/Index";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Download from "./images/Vectors/Download";
import { Link } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "./Utils/useApi";

const TransplantReport = () => {
  const [expanded, setExpanded] = useState(false);
  const [pageData, setPageData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/transplant_reports.php`);
        setPageData(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  if (isLoading) {
    return <div className="preloader"></div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <main className="col-12 float-start p-100 pt-0">
        <Container>
          <BreadCrumb pageName={"TRANSPLANT REPORT"} />
        </Container>
        <WebContainer>
          <div className="col-lg-10 col-12 m-auto accordionTransplant">
            <Title
              firstHeading={pageData.reportsHead.heading}
              secondHeading={pageData.reportsHead.sub_heading}
            />
            {pageData.reports.map((report, index) => (
              <Accordion
                key={index}
                expanded={expanded === `panel${index}`}
                onChange={handleChange(`panel${index}`)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}a-content`}
                  id={`panel${index}a-header`}
                >
                  <Typography>
                    <span className="heading5 fw-400">{report.year}</span>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <div className="col-12 float-start row transPlant">
                      {report.months.map((Data, monthIndex) => (
                        <Link
                          to={Data.filePath}
                          key={monthIndex}
                          target="_blank"
                          className="downloadBox col-lg-2 col-6"
                        >
                          <div>
                            <h5 className="heading5 m-0 text-uppercase fw-600">
                              {Data.month}
                            </h5>
                            <Download />
                            <h6 className=" mt-2  fw-600">{Data.name}</h6>
                          </div>
                        </Link>
                      ))}
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </WebContainer>
      </main>
    </>
  );
};

export default TransplantReport;
