import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Fill from '../Button/Fill/Index';

const Form = ({ brochure }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    dept_name: "",
    message: "",
    source: "default",  // Hidden field with default value
  });

  const [formErrors, setFormErrors] = useState({
    name: false,
    email: false,
    phone: false,
    dept_name: false,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const [currentUrl, setCurrentUrl] = useState("");
  const [departments, setDepartments] = useState([]);

  useEffect(() => {
    setCurrentUrl(window.location.href);

    fetch("https://triverseadvertising.com/patel_website/admin-portal/api/doctor_filter.php")
      .then((response) => response.json())
      .then((data) => {
        const sortedDepartments = data.sort((a, b) => {
          const nameA = a.name.replace(/^Patel\s*/i, "").toLowerCase();
          const nameB = b.name.replace(/^Patel\s*/i, "").toLowerCase();
          return nameA.localeCompare(nameB);
        });
        setDepartments(sortedDepartments);
      })
      .catch((error) => console.error("Error fetching departments:", error));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedFormData = { ...formData, [name]: value };

    // Validate field on change
    let error = false;
    if (name === "name" && value.trim() === "") {
      error = true;
    }
    if (name === "email" && (value.trim() === "" || !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(value))) {
      error = true;
    }
    if (name === "phone" && (value.trim() === "" || !/^[0-9]{10}$/.test(value))) {
      error = true;
    }
    if (name === "dept_name" && value.trim() === "") {
      error = true;
    }

    setFormData(updatedFormData);
    setFormErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  };

  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      phone: "",
      dept_name: "",
      message: "",
      source: "default",
    });
    setFormErrors({
      name: false,
      email: false,
      phone: false,
      dept_name: false,
    });
  };

  const validateForm = () => {
    const errors = {
      name: formData.name.trim() === "",
      email: formData.email.trim() === "" || !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(formData.email),
      phone: formData.phone.trim() === "" || !/^[0-9]{10}$/.test(formData.phone),
      dept_name: formData.dept_name.trim() === "",
    };

    setFormErrors(errors);

    return !Object.values(errors).some((error) => error);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      setIsSubmitting(true);

      const formDataWithUrl = {
        ...formData,
        url: currentUrl,
      };

      console.log("Form data:", formDataWithUrl);

      fetch("https://triverseadvertising.com/patel_website/admin-portal/api/appointment_submit_form.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formDataWithUrl),
      })
        .then((response) => response.json())
        .then((data) => {
          setIsSubmitting(false);
          console.log("Server response:", data);
          if (data.success) {
            alert("Thank you for submitting the form!");
            resetForm();
          } else {
            alert("There was an error submitting the form.");
          }
        })
        .catch((error) => {
          setIsSubmitting(false);
          console.error("Error:", error);
          alert("There was an error submitting the form.");
        });

      if (brochure) {
        window.open(brochure, "_blank");
      }
    }
  };

  return (
    <>
      <div
        className="col-12 d-inline"
        data-aos="fade-up"
        data-aos-easing="ease-in"
        data-aos-offset="50"
        data-aos-duration="600"
        data-aos-once="true"
      >
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-lg-6 col-12 mb-3">
              <TextField
                name="name"
                label="Name"
                className="modifiedinput"
                value={formData.name}
                onChange={handleChange}
                fullWidth
                error={formErrors.name}
                helperText={formErrors.name ? "Name is required" : ""}
              />
            </div>
            <div className="col-lg-6 col-12 mb-3">
              <TextField
                name="email"
                label="Email"
                className="modifiedinput"
                value={formData.email}
                onChange={handleChange}
                fullWidth
                error={formErrors.email}
                helperText={formErrors.email ? "Invalid email format" : ""}
              />
            </div>
            <div className="col-lg-6 col-12 mb-3">
              <TextField
                name="phone"
                label="Phone"
                className="modifiedinput"
                value={formData.phone}
                onChange={handleChange}
                fullWidth
                error={formErrors.phone}
                helperText={formErrors.phone ? "Invalid phone number format" : ""}
              />
            </div>
            <div className="col-lg-6 col-12 mb-3">
              <Select
                name="dept_name"
                value={formData.dept_name}
                className="modifiedinput"
                onChange={handleChange}
                fullWidth
                displayEmpty
                error={formErrors.dept_name}
              >
                <MenuItem value="" disabled>
                  Select Department
                </MenuItem>
                {departments.map((department) => (
                  <MenuItem key={department.name} value={department.name}>
                    {department.name}
                  </MenuItem>
                ))}
              </Select>
              {formErrors.dept_name && (
                <p className="erroMessage MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained MuiFormHelperText-filled css-1wc848c-MuiFormHelperText-root" id="dept_name-helper-text">
                  Department is required
                </p>
              )}
            </div>
            <div className="col-lg-12 col-12">
              <TextField
                name="message"
                label="Message"
                className="modifiedinput"
                value={formData.message}
                onChange={handleChange}
                fullWidth
              />
            </div>
            <div className="col-12 float-start mt-4 mb-5">
              <ul>
                <li>*Doctors’ appointments are available from 9:00 AM to 5:00 PM, Monday through Saturday. </li>
                <li>*The clinic is closed on Sundays and the following holidays: Republic Day, Holi, Independence Day, Dussehra and Diwali.</li>
                <li>*Same-day appointments are not available.</li>
              </ul>
            </div>
            <div className="col-12 text-center">
              <div className="col-lg-3 col-12 webbtn">
                <Fill
                  buttonText1={"Submit"}
                  buttonText2={"submit"}
                  _class={"secondarybtn"}
                  isSubmit={true}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Form;
