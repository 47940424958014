import React from "react";
import Arrow from "../../../images/Vectors/nextArrow";
import { Link } from "react-router-dom";

const SubMenu = ({ submenus, showViewAllLink }) => {
  return (
    <div className="dropdown-content">
      <div className="submenu">
        <div className="container">
          <div className="row">
            <div className="col-lg-4"></div>
            <div className="col-lg-8 col-12 py-5">
              <div className="submenu_Child">
                {submenus.map((submenu, index) => (
                  <div
                    className="submenu_row d-flex align-items-center col-12 float-start"
                    key={index}
                  >
                    <div className="submenuicons">{submenu.icon}</div>
                    <div className="submenucontent px-2">
                      {submenu.link ? (
                        <Link to={submenu.link}>
                          <p>{submenu.text}</p>
                        </Link>
                      ) : submenu.link2 ? (
                        <a href={submenu.link2} target="_blank" rel="noreferrer">
                          <p>{submenu.text}</p>
                        </a>
                      ) : (
                        <p>{submenu.text}</p>
                      )}
                    </div>
                  </div>
                ))}
                {showViewAllLink && (
                  <div className="submenu_row mt-4 pt-4 d-flex align-items-center col-12 float-start border-0">
                    <a href="/centres-of-excellence">
                      <p>
                        View All Centres of Excellence
                        <Arrow themeColor="primary-color" />
                      </p>
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubMenu;
