import React from 'react';
import ImageTag from '../ImageTag/Index';
import DownCircle from '../../images/Vectors/DownCircle';

const TreatmentCard = ({
  heroHeading,
  slides,
  _parentButton,
  slide__content,
  sliderImageWidth,
  sliderImageHeight,
  msliderImageWidth,
  msliderImageHeight,
  sliderClass,
  sliderImageClass,
  showButton,
  showCircle,
  circlePosition,
  showStar,
  onSlideClick,
  sliderChild,
  mShow,
  activeSlide,
  mobileImage,
  responsive = [],
  showContent = true,
  loop = false,
}) => {
  return (
    <div className="col-12 float-start">
      <div className="row flex-center">
        {slides.map((slide, index) => (
        <div
        className={`col-lg-4 col-sm-6 col-12 ${activeSlide === index ? 'active' : ''}`}
        key={index}
        onClick={() => onSlideClick && onSlideClick(slide, index)}
      >
            <div className="slide position-relative overflow-hidden doctorslide">
              <div className="doctorsprofile">
                <ImageTag
                  ImagePath={slide.imagePath}
                  ImageAlt={slide.imageAlt || `Slide ${index + 1}`}
                  ImageWidth={sliderImageWidth}
                  ImageHeight={sliderImageHeight}
                  ImageClass={`w-100 sliderClass ${mShow} `}
                />
              </div>
                <div className="doctorsinfo col-12 float-start p-4 gray-background technolog__content">
                  <h3>{slide.title}</h3>
                  <div className="col-12 float-start d-none"></div>
                  {slide.content && (
                  <button className={`border-0 downarrow ${_parentButton}`}>
                    <DownCircle />
                  </button>
                )}
                </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TreatmentCard;
