import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "./Utils/useApi";
import Container from "./components/Container/Index";
import BreadCrumb from "./components/BreadCrumb/Index";
import Title from "./components/Title/Index";
import Quote from "./images/Vectors/Quote";
import WebContainer from "./components/WebContainer/Index"

const Testimonial = () => {
  const [pageData, setPageData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/home.php`);
        setPageData(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  if (isLoading) {
    return <div className="preloader"></div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const { testimonials } = pageData || {};

  return (
    <>
      <Container _parentClass="gray_Background">
        <BreadCrumb pageName={"Testimonials"} />
      </Container>
      <WebContainer _parentClass="doctors p-100 gray_Background">
        <Title firstHeading={"PATIENT TESTIMONIALS"} secondHeading={testimonials.title} />
        <div className="col-12 float-start">
          <div className="row">
            {testimonials.testimonialsData.map((testimonial, index) => (
              <div key={index} className="col-lg-4 col-12">
                <div className="slide position-relative overflow-hidden doctorslide testimonialslide">
                  <div className="testimonialcont col-12 float-start bg-white d-flex flex-column-reverse">
                    <div className="circle flex-center col-lg-10 col-12 m-auto circlePosition position-absolute">
                      <div className="iconcircle col-lg-3 col-xs-6 col-12 mb-50 flex-center text-center gap-3">
                        <span><Quote /></span>
                      </div>
                    </div>
                    <div className="star">
                      {Array.from({ length: testimonial.stars }).map((_, starIndex) => (
                        <i key={starIndex} className="fa fa-star"></i>
                      ))}
                    </div>
                    <h3>{testimonial.title}</h3>
                    <div className="col-12 float-start">
                      <p dangerouslySetInnerHTML={{ __html: testimonial.content }} />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </WebContainer>
    </>
  );
};

export default Testimonial;
